export const G_AUTH_CODE = "googleAuthCode";
export const TOKEN = "token";
export const REFRESH_TOKEN = "refreshToken";
export const USER = "user";
export const OPTIONS_CHECKED = "optionsChecked";

// NAMES (TITLES)
export const NEW_PHASE = "New Phase";
export const NEW_PHASE_DESCR = "New Phase Description";
export const NEW_GOAL = "New Goal";
export const NEW_GOAL_DESCR = "New Goal Description";
export const NEW_SUB_GOAL = "New sub-goal name";
export const NEW_QUESTION = "New Question?";
export const NEW_OPTION = "New option";
