import { popupCenter } from "utils";
import { getGoogleAuthUrl, postGoogleAuth, revokeToken } from "api";
import { G_AUTH_CODE, REFRESH_TOKEN } from "utils/constants";

export type UserType = {
  email: string;
  role: string;
  name: string;
  profilePictureUrl: string;
};

export type AuthProvidersResponse = {
  accessToken: string;
  refreshToken: string;
  user: UserType;
};

export const googleAuthProvider = {
  checkAuthCode() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const googleAuthCode = urlParams.get("code") || "";
    window.localStorage.setItem(G_AUTH_CODE, googleAuthCode);
  },
  async signin(callback: (resp: AuthProvidersResponse) => void) {
    const authUrl = await getGoogleAuthUrl();
    if (typeof authUrl === "string") {
      const authWindow = popupCenter({
        url: authUrl,
        title: "Google OAuth",
      });

      let googleAuthCode;

      const checkAuthCode = () => {
        googleAuthCode = window.localStorage.getItem(G_AUTH_CODE);
        window.localStorage.removeItem(G_AUTH_CODE);
        if (googleAuthCode) {
          clearInterval(checkAuthCodeInterval);
          authWindow?.close();
          handleAuth(googleAuthCode);
        }
      };

      const checkAuthCodeInterval = setInterval(checkAuthCode, 500);

      const handleAuth = async (googleAuthCode: string) => {
        const resp = await postGoogleAuth(googleAuthCode);
        return callback({
          accessToken: resp.accessToken,
          refreshToken: resp.refreshToken,
          user: {
            email: resp.user.email,
            role: resp.user.role,
            name: resp.user.name,
            profilePictureUrl: resp.user.profilePictureUrl,
          },
        });
      };
    } else {
      alert("Something went wrong. Please contact administrator.");
    }
  },
};

export const revokeTokenProvider = {
  async revoke(callback: () => void) {
    const refreshToken = window.localStorage.getItem(REFRESH_TOKEN) || "";

    if (refreshToken) {
      await revokeToken(refreshToken);
    }
    return callback();
  },
};
