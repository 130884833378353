import axios from "axios";
import { clearPersistedData } from "utils";
import { REFRESH_TOKEN, TOKEN } from "utils/constants";

const baseApiUrl = process.env.REACT_APP_API_URL;

const apiInstance = axios.create({
  baseURL: `${baseApiUrl}/api`,
});

apiInstance.interceptors.request.use(
  (config) => {
    const token = window.localStorage.getItem(TOKEN);
    if (token) {
      config.headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

apiInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const {
          data: { accessToken },
        } = (
          await apiInstance.post("/refresh-token", {
            token: window.localStorage.getItem(REFRESH_TOKEN),
          })
        ).data;
        window.localStorage.setItem(TOKEN, accessToken);
        error.response.config.headers[
          "Authorization"
        ] = `Bearer ${accessToken}`;
        return apiInstance(originalRequest);
      } catch (e) {
        clearPersistedData();
      }
    }
    return Promise.reject(error);
  }
);

export const fetchData = async (
  method: "GET" | "POST" | "PATCH" | "DELETE",
  url: string,
  body?: object
) => {
  try {
    let response;

    if (method === "GET") {
      response = await apiInstance.get(url);
    }
    if (method === "POST") {
      response = await apiInstance.post(url, body);
    }
    if (method === "PATCH") {
      response = await apiInstance.patch(url, body);
    }
    if (method === "DELETE") {
      response = await apiInstance.delete(url);
    }

    if (response?.data?.success || response?.status === 204) {
      return response?.data?.data || true;
    } else {
      throw new Error(response?.statusText);
    }
  } catch (error: any) {
    if (error?.response?.status === 401) {
      window.location.replace("/login");
    }
    return error;
  }
};
