import React from "react";
import styles from "./Modal.module.scss";
import Typography from "components/atoms/Typography";

interface ModalProps {
  title: string;
  subTitle: string;
  variant?: "logout" | "delete";
  onClickCancel?: () => void;
  onClickYes?: () => void;
}

const Modal = ({
  title,
  subTitle,
  variant = "logout",
  onClickCancel,
  onClickYes,
}: ModalProps) => {
  return (
    <div className={`${styles.modalOverlay} ${styles["variant-" + variant]}`}>
      <div className={styles.modal}>
        <Typography tag="h2" variant="modalTitle">
          {title}
        </Typography>
        <Typography tag="span" variant="menuItem">
          {subTitle}
        </Typography>
        <div className={styles.modalButtons}>
          <Typography tag="span" variant="modalButton" onClick={onClickCancel}>
            CANCEL
          </Typography>
          <Typography tag="span" variant="modalButton" onClick={onClickYes}>
            YES
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default Modal;
