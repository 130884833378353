import React, { useState, useEffect, useMemo } from "react";
import { flatten } from "lodash";
import { useParams, useNavigate } from "react-router-dom";
import { useSnackbar } from "state/SnackbarState";
import { useEditReport } from "state/ReportEditState";
import { useDeleteElement } from "hooks";
import {
  getReport,
  getSingleHistoryReport,
  createGoogleSheetsReport,
  createPDFReport,
} from "api";
import { useSelectedOptions } from "state/SelectedOptionsState";
import type { getReportResponseType, ReportType } from "api/types";
import CommonLayout from "layouts/CommonLayout";
import SelectReportFormat from "components/molecules/SelectReportFormat";
import Typography from "components/atoms/Typography";
import Button from "components/atoms/Button";
import Modal from "components/molecules/Modal";
import styles from "./Report.module.scss";

const Report = () => {
  const [report, setReport] = useState<getReportResponseType[]>();
  const [historyReport, setHistoryReport] = useState<ReportType>();
  const [openSelectReportFormat, setOpenSelectReportFormat] =
    useState<boolean>(false);
  const { elementToDelete, handleDelete, setElementToDelete } =
    useDeleteElement();
  const { selectedOptionsIds, setSelectedOptionsIds } = useSelectedOptions();
  const { snackbarList } = useSnackbar();
  const { handleEditReport, handleExitEditMode } = useEditReport();
  const { reportId } = useParams();
  const navigate = useNavigate();

  const selectedOptionsString = useMemo(
    () => flatten(Object.values(selectedOptionsIds)).toString(),
    [selectedOptionsIds]
  );

  const getAndSetHistoryReport: () => Promise<void> = async () => {
    if (reportId) {
      const historyReport = await getSingleHistoryReport(parseInt(reportId));
      setHistoryReport(historyReport);
    }
  };

  const getAndSetReport: () => Promise<void> = async () => {
    getAndSetHistoryReport();
    const report = await getReport(selectedOptionsString);
    setReport(report);
  };

  const getAndSetPDFReport: () => Promise<void> = async () => {
    setOpenSelectReportFormat(false);
    const base64: string = await createPDFReport(
      selectedOptionsString,
      historyReport?.name || "DAD Project - Google Sheets"
    );
    const linkSource: string = `data:application/pdf;base64,${base64}`;
    const downloadLink: HTMLAnchorElement = document.createElement("a");
    const fileName: string = `${historyReport?.name || "DAD Project - PDF"
      }.pdf`;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  const getAndSetGoogleSheetsReport: () => Promise<void> = async () => {
    setOpenSelectReportFormat(false);
    const url: string = await createGoogleSheetsReport(
      selectedOptionsString,
      historyReport?.name || "DAD Project - Google Sheets"
    );
    if (url) window.open(url);
  };

  const deleteHistoryReport: () => Promise<void> = async () => {
    try {
      await handleDelete("REPORT");
      setSelectedOptionsIds({});
      navigate("/");
    } catch (error) { }
  };

  const goToReportEditMode = () => {
    handleEditReport(
      selectedOptionsString,
      historyReport?.id,
      historyReport?.name
    );
    navigate(`/phases`);
  };

  useEffect(() => {
    getAndSetReport();
    // eslint-disable-next-line
  }, [snackbarList]);

  useEffect(() => {
    handleExitEditMode(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CommonLayout
      title="This is your Agile Strategy Report"
      variant="report"
      backButton="Back to Home"
      navigatePath="/"
    >
      <>
        <div className={styles.report}>
          {report && (
            <div className={styles.container}>
              <Typography
                tag="h1"
                variant="reportName"
                className={styles.reportName}
              >
                {historyReport?.name || "New report"}
              </Typography>
              {!!report.length &&
                report.map((phase) => (
                  <div key={phase.id} className={styles.reportContainer}>
                    <div className={styles.information}>
                      <Typography
                        className={styles.title}
                        tag="h1"
                        variant="report"
                      >
                        {`Phase: ${phase?.name}`}
                      </Typography>
                      <Typography tag="p" variant="report">
                        {phase?.text}
                      </Typography>
                      <hr className={styles.hr} />
                    </div>
                    <div className={styles.information}>
                      {!!phase.children.length &&
                        phase.children.map((goal) => (
                          <div className={styles.goal} key={goal.id}>
                            <Typography
                              className={styles.subTitle}
                              tag="h2"
                              variant="report"
                            >
                              {`Phase Goal: ${goal.name}`}
                            </Typography>
                            <Typography tag="p" variant="report">
                              {goal.text}
                            </Typography>
                            {!!goal.children.length &&
                              goal.children.map((subGoal) => (
                                <div
                                  className={styles.subgoal}
                                  key={subGoal.id}
                                >
                                  <ul className={styles.list}>
                                    <li className={styles.name}>
                                      {subGoal.name}
                                    </li>
                                  </ul>
                                  {!!subGoal.children.length &&
                                    subGoal.children.map((option) => (
                                      <ul
                                        key={option.id}
                                        className={styles.options}
                                      >
                                        <div className={styles.optionsCont}>
                                          <div
                                            className={styles.checkMark}
                                          ></div>
                                          <li>{option.name}</li>
                                        </div>
                                      </ul>
                                    ))}
                                </div>
                              ))}
                            <hr className={styles.hr} />
                          </div>
                        ))}
                    </div>
                  </div>
                ))}
            </div>
          )}
          <div className={styles.buttons}>
            <Button
              buttonType="large"
              variant="tertiary"
              onClick={goToReportEditMode}
            >
              EDIT REPORT
            </Button>
            <div className={styles.exportButton}>
              <Button
                buttonType="large"
                variant="tertiary"
                onClick={() =>
                  setOpenSelectReportFormat(!openSelectReportFormat)
                }
              >
                <>
                  EXPORT
                  <div className={styles.exportArrow}></div>
                </>
              </Button>
              {openSelectReportFormat && (
                <SelectReportFormat
                  className={styles.selectReport}
                  onClickPDF={getAndSetPDFReport}
                  onClickGoogleSheets={getAndSetGoogleSheetsReport}
                />
              )}
            </div>
            <Button
              buttonType="large"
              variant="quinary"
              onClick={() =>
                setElementToDelete({
                  name: historyReport?.name,
                  id: historyReport?.id,
                })
              }
            >
              DELETE
            </Button>
          </div>
          {elementToDelete && (
            <Modal
              title={`Delete Report`}
              variant="delete"
              subTitle={`Are you sure, you want to delete this report?`}
              onClickCancel={() => setElementToDelete(null)}
              onClickYes={deleteHistoryReport}
            />
          )}
        </div>
      </>
    </CommonLayout>
  );
};

export default Report;
