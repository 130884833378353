import React, { useEffect, useState } from "react";
import styles from "./Users.module.scss";
import ToggleUserRole from "components/molecules/ToggleUserRole";
import { useSnackbar } from "state/SnackbarState";
import { getUsersPaginated, updateUserRole } from "api";
import { getUsersResponseType } from "api/types";
import Avatar from "components/atoms/Avatar";
import Logo from "components/atoms/Logo";
import Button from "components/atoms/Button";
import SnackBar from "components/molecules/Snackbar";
import { useNavigate } from "react-router-dom";
import { ReactComponent as PreviousIco } from "assets/icons/ico-lesser.svg";
import { ReactComponent as NextIco } from "assets/icons/ico-greater.svg";
import { ReactComponent as DropdownIco } from "assets/icons/ico-arrow-down.svg";
import Typography from "components/atoms/Typography";

const Users = () => {
  const [userList, setUserList] = useState<getUsersResponseType>();
  const [perPageCount, setPerPageCount] = useState(5);
  const [totalUsers, setTotalUsers] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const { snackbar, snackbarList = [] } = useSnackbar();
  const navigate = useNavigate();

  const getUsers = async (page = 1, countPerPage = perPageCount) => {
    const users = await getUsersPaginated(page, countPerPage);
    setTotalUsers(users.total);
    setUserList(users);
  };

  const handleUserRoleChange = async (userId: number, role: string) => {
    const roleUpdate = role === "Administrator" ? "user" : "admin";
    const user = userList?.users.find((user) => Number(user.id) === userId);
    try {
      await updateUserRole(userId, roleUpdate);
      snackbar.success(
        `${user?.name} is ${
          roleUpdate === "admin" ? "an administrator" : "a user"
        } now`
      );
      await getUsers();
    } catch (e) {
      snackbar.error(`${user?.name} was not updated`);
      throw new Error();
    }
  };

  const isThereANextPage = () =>
    currentPage >= Math.ceil(totalUsers / perPageCount);

  const isThereAPreviousPage = () => currentPage - 1 < 1;

  const handlePageChange = (action: string) => {
    let page = currentPage;
    if (action === "next") {
      if (isThereANextPage()) {
        return;
      }
      page++;
    }
    if (action === "previous") {
      if (isThereAPreviousPage()) {
        return;
      }
      page--;
    }
    setCurrentPage(page);
    getUsers(page, perPageCount);
  };

  const handleCountChange = async (count: number) => {
    setPerPageCount(count);
    getUsers(1, perPageCount);
    setCurrentPage(1);
  };

  const handleVisibleUsersCount = (): string => {
    const countStart = (currentPage - 1) * perPageCount + 1;
    const numberOfUsersReturned = userList ? userList.users.length : 0;
    const countEnd = countStart + numberOfUsersReturned - 1;
    return `${countStart}-${countEnd}`;
  };

  useEffect(() => {
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [perPageCount]);

  return (
    <div className={styles.container}>
      <div className={styles.logos}>
        <Logo type="aeText" url="https://agileengine.com/" />
      </div>
      <div className={styles.title}>
        <Typography tag="h1" variant="tabs">
          Users List
        </Typography>
      </div>
      <div className={styles.buttonCont}>
        <Button
          buttonType="back"
          variant="quaternary"
          onClick={() => navigate("/")}
        >
          Back to Home
        </Button>
      </div>
      <div className={styles.tableContainer}>
        <table>
          <thead>
            <tr>
              <th>User</th>
              <th>Contact</th>
              <th>Role</th>
            </tr>
          </thead>
          <tbody>
            {userList &&
              userList?.users.map((user) => {
                return (
                  <tr key={user.id}>
                    <td>
                      <div className={styles.userCell}>
                        <Avatar
                          avatarUrl={user.profilePictureUrl}
                          email={user.email}
                        />
                        <Typography tag="span" variant="user">
                          {user.name}
                        </Typography>
                      </div>
                    </td>
                    <td>
                      <Typography tag="span" variant="user-email">
                        {user.email}
                      </Typography>
                    </td>
                    <td>
                      <ToggleUserRole
                        userId={Number(user.id)}
                        userRole={
                          user.role === "admin" ? "Administrator" : "User"
                        }
                        onChange={handleUserRoleChange}
                      />
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        <div className={styles.pagination}>
          <div className={styles.paginationPageContainer}>
            <Typography tag="span" variant="pagination">
              {`Rows per page: `}
            </Typography>
            <div className={styles.paginationPageCount}>
              <select
                className={styles.paginationPageCountSelect}
                defaultValue={perPageCount}
                onChange={(e) => handleCountChange(Number(e.target.value))}
              >
                <option value={1}>1</option>
                <option value={2}>2</option>
                <option value={3}>3</option>
                <option value={4}>4</option>
                <option value={5}>5</option>
                <option value={6}>6</option>
                <option value={7}>7</option>
                <option value={8}>8</option>
                <option value={9}>9</option>
                <option value={10}>10</option>
              </select>
              <DropdownIco />
            </div>
          </div>

          <Typography tag="span" variant="pagination-count">
            {`${handleVisibleUsersCount()} of ${totalUsers}`}
          </Typography>
          <PreviousIco
            className={`${styles.paginationIco} ${
              isThereAPreviousPage() ? styles.paginationIcoInactive : ""
            }`}
            onClick={() => handlePageChange("previous")}
          />
          <NextIco
            className={`${styles.paginationIco} ${
              isThereANextPage() ? styles.paginationIcoInactive : ""
            }`}
            onClick={() => handlePageChange("next")}
          />
        </div>
      </div>
      {snackbarList.length > 0 && (
        <div className={styles.snackbarContainer}>
          {snackbarList.map((snack) => (
            <SnackBar
              type={snack.type}
              snackBarDescription={snack.response}
              key={snack.id}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default Users;
