import React from "react";
import Typography from "components/atoms/Typography";
import styles from "./SelectReportFormat.module.scss";

interface SelectReportFormatProps {
  className?: string;
  onClickPDF: VoidFunction;
  onClickGoogleSheets: VoidFunction;
}

const SelectReportFormat = ({
  className = "",
  onClickPDF = () => {},
  onClickGoogleSheets = () => {},
}: SelectReportFormatProps) => {
  return (
    <div className={`${styles.select} ${className}`}>
      <div className={styles.option} onClick={onClickPDF}>
        <Typography tag="h3" variant="exportReport" className={styles.pdf}>
          PDF
        </Typography>
      </div>
      <div className={styles.option} onClick={onClickGoogleSheets}>
        <Typography
          tag="h3"
          variant="exportReport"
          className={styles.googleSheet}
        >
          Google sheet
        </Typography>
      </div>
    </div>
  );
};

export default SelectReportFormat;
