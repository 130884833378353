import agileEngineTextLogo from "assets/logos/rocket-ae.svg";
import agileEngineNoTextLogo from "assets/logos/rocket.svg";
import productManagementLogo from "assets/logos/pmi.png";
import styles from "./Logo.module.scss";

type imgLogoTypes = {
  [key: string]: {
    src: string;
    alt: string;
  };
};

const imgLogos: imgLogoTypes = {
  aeText: {
    src: agileEngineTextLogo,
    alt: "Agile Engine Logo",
  },
  aeNoText: {
    src: agileEngineNoTextLogo,
    alt: "Agile Engine Logo",
  },
  pmi: {
    src: productManagementLogo,
    alt: "Product Management Institute Logo",
  },
};

interface LogoProps {
  type: "aeText" | "pmi" | "aeNoText";
  url?: string;
  className?: string;
}

const Logo = ({ type, url = "", className = "" }: LogoProps) => {
  const image = (
    <img
      className={styles.image}
      src={imgLogos[type].src}
      alt={imgLogos[type].alt}
    />
  );

  return (
    <div className={`${styles.logo} ${className}`}>
      {url ? (
        // eslint-disable-next-line react/jsx-no-target-blank
        <a href={url} target="_blank" className={styles.link}>
          {image}
        </a>
      ) : (
        image
      )}
    </div>
  );
};

export default Logo;
