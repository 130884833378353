import { useCallback } from "react";
import { createElement } from "api";
import { useSnackbar } from "state/SnackbarState";
import { postElementResponseType } from "api/types";

type addElementType = {
  parentId?: null | number;
  type: string;
  name?: string;
  text?: string;
};

const useAddElement = (): {
  handleAddElement: (
    element: addElementType
  ) => Promise<postElementResponseType>;
} => {
  const { snackbar } = useSnackbar();

  const handleAddElement = useCallback(async (element: addElementType) => {
    try {
      const response = await createElement(element);
      snackbar.success(`${response.name} added successfully`);
      return response;
    } catch (e) {
      snackbar.error(`A new ${element.type} not added`);
      throw new Error();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { handleAddElement };
};

export default useAddElement;
