import React, { ReactChild } from "react";
import SimpleBarReact from "simplebar-react";
import styles from "./ScrollBox.module.scss";

// lib css import
import "simplebar/src/simplebar.css";

interface ScrollBoxProps {
  className?: string;
  children: string | ReactChild;
  variant: "options" | "phases" | "goals" | "report";
  scrollableNoderef?: any;
}

const ScrollBox = ({
  children,
  className = "",
  variant,
  scrollableNoderef = "",
}: ScrollBoxProps) => {
  const refProp = scrollableNoderef ? { ref: scrollableNoderef } : {};
  return (
    <SimpleBarReact
      className={`${styles.scrollBox} ${styles[variant]}`}
      scrollbarMinSize={32}
      scrollbarMaxSize={32}
      {...refProp}
    >
      <div className={`${styles.container} ${className}`}>{children}</div>
    </SimpleBarReact>
  );
};

export default ScrollBox;
