import React, { useState } from "react";
import IconButton from "components/atoms/IconButton/IconButton";
import Typography from "components/atoms/Typography";
import styles from "./NavigationBar.module.scss";
import Arrow from "components/atoms/Arrow";

type RenderArrowType = {
  [key: string]: JSX.Element;
};

interface NavigationBarProps {
  type: "homeNav" | "phasesTitleNav" | "usersNav" | "phasesNav" | "goalsNav";
  className?: string;
  onClick?: () => void;
  children?: string | React.ReactChild | React.ReactNode | JSX.Element;
  content: string;
}

const NavigationBar = ({
  type,
  className = "",
  onClick = () => { },
  content = "",
  children,
}: NavigationBarProps) => {
  const [display, setDisplay] = useState<boolean>(false);

  const handleDisplayContent = () => {
    setDisplay(!display);
  };

  const renderArrow: RenderArrowType = {
    phasesTitleNav: <Arrow type="phasesTitleNav" displayContent={display} />,
    phasesNav: (
      <Arrow
        className={display ? styles.arrowHighlight : ""}
        type="phasesNav"
        displayContent={display}
      />
    ),
    goalsNav: <Arrow type="goalsNav" displayContent={display} />,
  };

  return (
    <div className={styles.container}>
      <div className={`${styles.navigationBar} ${className}`}>
        {(type === "homeNav" ||
          type === "phasesTitleNav" ||
          type === "usersNav") && (
            <IconButton
              onClick={onClick}
              variant={type}
              className={styles.button}
            />
          )}
        <div className={styles.navigation} onClick={handleDisplayContent}>
          <Typography
            onClick={onClick}
            tag="span"
            className={`${styles.navContent}  ${display && type === "phasesNav" && styles.fontHighlight
              } `}
            variant={
              type === "phasesTitleNav" ||
                type === "homeNav" ||
                type === "usersNav"
                ? "menuBar"
                : type === "phasesNav"
                  ? "phasesBar"
                  : "goalsBar"
            }
          >
            {content}
          </Typography>
          {renderArrow[type]}
        </div>
      </div>
      <div className={display ? styles.display : styles.displayOff}>
        {children}
      </div>
    </div>
  );
};

export default NavigationBar;
