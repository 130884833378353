import React from "react";
import styles from "./Arrow.module.scss";

interface ArrowProps {
  type?: "phasesTitleNav" | "phasesNav" | "goalsNav";
  displayContent?: boolean;
  className?: string;
}

const Arrow = ({ type, displayContent, className = "" }: ArrowProps) => {
  return (
    <div className={`${styles.container} ${className}`}>
      <div
        className={`${!displayContent ? styles.arrow : styles.arrowUp} ${
          styles["type-" + type]
        }`}
      ></div>
    </div>
  );
};

export default Arrow;
