import React, { ReactElement } from "react";

interface LoginLayoutProps {
  children: ReactElement;
}

const LoginLayout = ({ children }: LoginLayoutProps) => {
  return <>{children}</>;
};

export default LoginLayout;
