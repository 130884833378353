import { useCallback } from "react";
import { updateElement, updateStaticElement, updateReport } from "api";
import { useSnackbar } from "state/SnackbarState";

const keyLabels = {
  name: "'Name'",
  text: "'Text'",
  highlighted: "'Highlighted status'"
}

const useEditElement = () => {
  const { snackbar } = useSnackbar();

  const handleEditElement = useCallback(
    async (
      elementId: number,
      type: "STATIC" | "CONTENT" | "REPORT",
      element: { name?: string; text?: string; highlighted?: boolean },
      selectedReportOptions?: string
    ) => {
      try {
        let response;
        if (type === "STATIC") {
          response = await updateStaticElement(elementId, element);
        }
        if (type === "CONTENT") {
          response = await updateElement(elementId, element);
        }
        if (type === "REPORT") {
          if (selectedReportOptions) {
            response = await updateReport(
              elementId,
              selectedReportOptions,
              element?.name || ""
            );
          }
        }
        snackbar.success(
          `${Object.keys(element).map((e) => keyLabels[e as 'name' | 'text' | 'highlighted']).join(", ")} edited successfully`
        );
        return response;
      } catch (e) {
        snackbar.error(
          `${Object.keys(element).map((e) => keyLabels[e as 'name' | 'text' | 'highlighted']).join(", ")} was not edited`
        );
        throw new Error("");
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return { handleEditElement };
};

export default useEditElement;
