import { fetchData } from "api/handler";
import type {
  getStaticContentResponseType,
  getPhasesResponseType,
  postGoogleAuthResponseType,
  getSubgoalsResponseType,
  getGoalsResponseType,
  getReportResponseType,
  postElementResponseType,
  getAllResponseType,
  getUsersResponseType,
  ReportType,
} from "api/types";

/*
AUTH
 */

export const getGoogleAuthUrl = async () =>
  await fetchData("GET", "/google-auth-url");

export const postGoogleAuth = async (
  googleAuthCode: string
): Promise<postGoogleAuthResponseType> =>
  await fetchData("POST", "/google-auth", { code: googleAuthCode });

export const revokeToken = async (refreshToken: string): Promise<null> =>
  await fetchData("POST", "/logout", { token: refreshToken });

/*
STATIC CONTENT
 */

export const getStaticContentById = async (
  staticId: number
): Promise<getStaticContentResponseType[]> =>
  await fetchData("GET", `/static-content?id=${staticId}`);

export const getStaticContentByType = async (
  staticType: string
): Promise<getStaticContentResponseType[]> =>
  await fetchData("GET", `/static-content?type=${staticType}`);

export const updateStaticElement = async (
  staticId: number,
  staticBody: object
) => await fetchData("PATCH", `/static-content/${staticId}`, staticBody);

/*
CONTENT POINT
 */

export const getAllContent = async (): Promise<getAllResponseType[]> =>
  await fetchData("GET", "/content-points");

export const getMenuContent = async (): Promise<getAllResponseType[]> =>
  await fetchData("GET", "/content-point?children=3");

export const getPhases = async (): Promise<getPhasesResponseType[]> =>
  await fetchData("GET", "/content-point?children=0");

export const getGoals = async (
  phaseId: string
): Promise<getGoalsResponseType[]> =>
  await fetchData("GET", `/content-point?id=${phaseId}&children=1`);

export const getSubGoals = async (
  phaseId: string,
  goalId: string
): Promise<getSubgoalsResponseType[]> =>
  await fetchData(
    "GET",
    `/content-point?id=${goalId}&parentId=${phaseId}&children=2`
  );

export const updateElement = async (elementId: number, elementBody: object) =>
  await fetchData("PATCH", `/content-point/${elementId}`, elementBody);

export const deleteSelectedElement = async (elementId: number) =>
  await fetchData("DELETE", `/content-point/${elementId}`);

export const createElement = async (
  body: object
): Promise<postElementResponseType> =>
  await fetchData("POST", `/content-point`, body);

/*
CONTENT TREE
 */

export const getReport = async (
  selectedOptions: string
): Promise<getReportResponseType[]> =>
  await fetchData("GET", `/content-tree?childrenIds=${selectedOptions}`);

/*
SUMMARY
 */

export const getBaseReport = async (selectedOptions: string): Promise<string> =>
  await fetchData("GET", `/summary?childrenIds=${selectedOptions}`);

/*
USERS
  */

export const getUsersPaginated = async (
  page: number,
  perPage: number
): Promise<getUsersResponseType> =>
  await fetchData("GET", `/users?page=${page}&perPage=${perPage}`);

export const updateUserRole = async (userId: number, role: string) =>
  await fetchData("PATCH", `/users/${userId}`, { role: role });

export const createGoogleSheetsReport = async (
  selectedOptions: string,
  reportName: string
): Promise<string> =>
  await fetchData(
    "POST",
    `report-google-sheets?childrenIds=${selectedOptions}`,
    { title: reportName }
  );

export const createPDFReport = async (
  selectedOptions: string,
  reportName: string
): Promise<string> =>
  await fetchData("POST", `/report-pdf?childrenIds=${selectedOptions}`, {
    title: reportName,
  });

/* REPORTS
 */

export const getHistoryReports = async (): Promise<ReportType[]> =>
  await fetchData("GET", `/reports`);

export const getSingleHistoryReport = async (
  historyReportId: number
): Promise<ReportType> => await fetchData("GET", `/report/${historyReportId}`);

export const createHistoryReport = async (
  selectedOptions: string,
  reportName: string
): Promise<ReportType> =>
  await fetchData("POST", `/report?contentPoints=${selectedOptions}`, {
    name: reportName,
  });

export const updateReport = async (
  reportId: number,
  selectedOptions: string,
  reportName: string
) =>
  await fetchData(
    "PATCH",
    `/report/${reportId}?contentPoints=${selectedOptions}`,
    { name: reportName }
  );

export const deleteHistoryReport = async (reportId: number) =>
  await fetchData("DELETE", `/report/${reportId}`);
