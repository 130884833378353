import React, { ReactElement, useState, useRef, useEffect } from "react";
import { useMatch } from "react-router-dom";
import { useAuth } from "state/AuthState";
import IconButton from "components/atoms/IconButton/IconButton";
import useEditElement from "hooks/useEditElement";
import sanitizeHtml from "sanitize-html";
import SelectReportFormat from "components/molecules/SelectReportFormat";
import { NEW_PHASE, NEW_GOAL } from "utils/constants";
import styles from "./ContentEditor.module.scss";
import { createGoogleSheetsReport, createPDFReport } from "api";

const sanitizeConf = {
  allowedTags: ["b", "i", "em", "strong", "a", "p", "br", "ul", "li", "ol"],
  allowedAttributes: { a: ["href"] },
};

type downloadType = {
  reportName: string;
  selectedReportOptions: Array<number>;
};

interface ContentEditorProps {
  id?: number;
  children: string | ReactElement;
  field: "name" | "text";
  allowHighlight?: boolean;
  isHighlighted?: boolean;
  isContentStatic?: boolean;
  className?: string;
  onDelete?: VoidFunction;
  onAdd?: VoidFunction;
  download?: downloadType;
  selectedOptions?: string;
  idx?: number;
}

const ContentEditor = ({
  id,
  children,
  field,
  isContentStatic = false,
  className = "",
  allowHighlight = false,
  isHighlighted = false,
  onDelete,
  onAdd,
  download,
  selectedOptions,
  idx
}: ContentEditorProps) => {
  const { user } = useAuth();
  const inputValue = useRef("");
  const { handleEditElement } = useEditElement();
  const [changedValue, setChangedValue] = useState("");
  const [highlightValue, setHighlightValue] = useState(false);
  const [changedHighlightValue, setChangedHighlightValue] = useState(false);
  const [openSelectReportFormat, setOpenSelectReportFormat] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const historyReport = useMatch("/reports");
  const isAdmin: boolean = user?.role === "admin";

  const handleConfirm = async () => {
    try {
      if (id && (inputValue.current.length > 0 || changedHighlightValue)) {
        await handleEditElement(
          id,
          isContentStatic ? "STATIC" : historyReport ? "REPORT" : "CONTENT",
          getElementToConfirm(),
          selectedOptions
        );
        resetChangedValues()
      }
    } catch (e) { }

    setIsEditing(false);
  };

  const getElementToConfirm = () => {
    const element: { name?: string; text?: string; highlighted?: boolean } = {};
    const value = sanitizeHtml(inputValue.current, sanitizeConf);
    if (inputValue.current.length > 0) {
      element[field] = value;
    }
    if (changedHighlightValue) {
      element["highlighted"] = highlightValue;
    }
    return element;
  }

  const resetChangedValues = () => {
    if (inputValue.current.length > 0) {
      setChangedValue(sanitizeHtml(inputValue.current, sanitizeConf));
    }
    inputValue.current = "";
    setChangedHighlightValue(false);
  }

  const handleCancelEdit = () => {
    setIsEditing(false);
  };

  const handleOnChangeInputValue = (text: string) => {
    inputValue.current = text;
  };

  const handleOnChangeHighlightValue = (value: boolean) => {
    setHighlightValue(value);
    setChangedHighlightValue(true);
  };

  const getAndSetPDFReport = async (
    selectedReportOptions: Array<number>,
    reportName: string
  ) => {
    const base64: string = await createPDFReport(
      selectedReportOptions.toString(),
      reportName || "DAD Project - Google Sheets"
    );
    const linkSource: string = `data:application/pdf;base64,${base64}`;
    const downloadLink: HTMLAnchorElement = document.createElement("a");
    const fileName: string = `${reportName || "DAD Project - PDF"}.pdf`;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  const getAndSetGoogleSheetsReport = async (
    selectedReportOptions: Array<number>,
    reportName: string
  ) => {
    const url: string = await createGoogleSheetsReport(
      selectedReportOptions.toString(),
      reportName || "DAD Project - Google Sheets"
    );
    if (url) window.open(url);
  };

  useEffect(() => {
    if (
      React.isValidElement(children) &&
      (children.props.children === NEW_PHASE ||
        children.props.children === NEW_GOAL) &&
      isAdmin
    ) {
      setIsEditing(true);
    }

    setHighlightValue(isHighlighted);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={`${styles.container} ${className}`}>
      {isEditing ? (
        <div className={styles.innerContainer}>
          <div className={styles.elAround}>
            <span className={styles.elBefore} />
            <span className={styles.elAfter} />
          </div>
          {React.isValidElement(children)
            ? React.cloneElement(children, {
              isEditable: true,
              onChange: handleOnChangeInputValue,
              className: `
                  ${children.props.className || ""} 
                  ${styles.input} 
                  ${highlightValue ? styles.highlightText : ""}`,
              children: changedValue ? changedValue : children.props.children,
              onClick: undefined,
            })
            : children}
        </div>
      ) : React.isValidElement(children) ? (
        React.cloneElement(children, {
          children: changedValue ? changedValue : children.props.children,
          className: `
            ${children.props.className || ""} 
            ${highlightValue ? styles.highlightText : ""}`,
        })
      ) : (
        children
      )}

      {(isAdmin || historyReport) && !isEditing && id && (
        <>
          <IconButton
            variant="edit"
            className={!historyReport ? styles.btnLeft : styles.btnLeft1}
            onClick={() => setIsEditing(true)}
          />
          {download?.reportName && (
            <>
              <IconButton
                variant="download"
                className={styles.btnMiddle}
                onClick={() =>
                  setOpenSelectReportFormat(!openSelectReportFormat)
                }
              />
              {openSelectReportFormat && (
                <SelectReportFormat
                  className={styles.selectReport}
                  onClickPDF={() =>
                    getAndSetPDFReport(
                      download.selectedReportOptions,
                      download.reportName
                    )
                  }
                  onClickGoogleSheets={() =>
                    getAndSetGoogleSheetsReport(
                      download.selectedReportOptions,
                      download.reportName
                    )
                  }
                />
              )}
            </>
          )}
          {typeof onDelete === "function" && (
            <IconButton
              variant="delete"
              onClick={onDelete}
              className={styles.btnRight}
            />
          )}
          {typeof onAdd === "function" && idx === 0 && (
            <IconButton
              variant="addSubGoal"
              onClick={onAdd}
              className={styles.btnAdd}
            />
          )}
        </>
      )}

      {isEditing && (
        <>
          <IconButton
            variant="confirmEdit"
            className={styles.btnLeft}
            onClick={handleConfirm}
          />
          {allowHighlight && (
            <IconButton
              variant="highlight"
              onClick={() => handleOnChangeHighlightValue(!highlightValue)}
              className={styles.btnRight}
              active={highlightValue}
            />
          )}
          <IconButton
            variant="rejectEdit"
            className={allowHighlight ? styles.btnAdd : styles.btnRight}
            onClick={handleCancelEdit}
          />
        </>
      )}
    </div>
  );
};

export default ContentEditor;
