import React from "react";
import styles from "./Avatar.module.scss";

interface AvatarProps {
  avatarUrl?: string;
  email: string;
  onClick?: () => void;
}

const handleInitials = (email: string): string => {
  const emailArr = email.split(".");
  let initials: string = "";
  if (emailArr.length > 2) {
    initials = emailArr[0][0].toUpperCase() + emailArr[1][0].toUpperCase();
  } else {
    initials = emailArr[0][0][0].toUpperCase();
  }
  return initials;
};

const Avatar = ({ avatarUrl = "", email, onClick = () => {} }: AvatarProps) => {
  return (
    <div onClick={onClick}>
      <div className={styles.container}>
        {avatarUrl ? (
          <img className={styles.avatarImg} src={avatarUrl} alt="Avatar" />
        ) : (
          <div className={styles.placeholder}>{handleInitials(email)}</div>
        )}
      </div>
    </div>
  );
};

export default Avatar;
