import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { xor, flatten } from "lodash";
import { getSubGoals } from "api";
import CommonLayout from "layouts/CommonLayout";
import Typography from "components/atoms/Typography";
import Button from "components/atoms/Button";
import ScrollBox from "components/molecules/Scrollbox";
import CheckBox from "components/atoms/Checkbox";
import { useSelectedOptions } from "state/SelectedOptionsState";
import type { getSubgoalsResponseType } from "api/types";
import styles from "./Options.module.scss";
import StepProgressBar from "components/molecules/StepProgressBar";
import IconButton from "components/atoms/IconButton";
import { ReactComponent as CloseIcon } from "assets/icons/ico-snack-close.svg";
import { ReactComponent as BackIco } from "assets/icons/ico-left-arrow.svg";
import { useEditReport } from "state/ReportEditState";
import ContentEditor from "components/molecules/ContentEditor";
import { CONTENT_ENUM } from "api/types";
import { NEW_OPTION, NEW_QUESTION, NEW_SUB_GOAL } from "utils/constants";
import { useAddElement, useDeleteElement } from "hooks";
import { useAuth } from "state/AuthState";
import Modal from "components/molecules/Modal";

const Options = () => {
  const navigate = useNavigate();
  const { goalId = "", phaseId = "" } = useParams();
  const { selectedOptionsIds, setSelectedOptionsIds } = useSelectedOptions();
  const { isEditingReport, handleExitEditMode } = useEditReport();
  const [goal, setGoal] = useState<getSubgoalsResponseType>();
  const { elementToDelete, setElementToDelete, handleDelete } =
    useDeleteElement();
  const { handleAddElement } = useAddElement();
  const scrollableNodeRef = useRef() as any;
  const { user } = useAuth();
  const isAdmin: boolean = user?.role === "admin";

  const getAndSetSubgoals = async () => {
    const [subGoals] = await getSubGoals(phaseId, goalId);
    setGoal(subGoals);
  };

  useEffect(() => {
    getAndSetSubgoals();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectedOptionsState = (id: number) => () => {
    const newValues = xor(selectedOptionsIds[goalId], [id]);
    setSelectedOptionsIds({ ...selectedOptionsIds, [goalId]: newValues });
  };

  const handleDeleteSubgoal = async () => {
    try {
      await handleDelete("CONTENT");
      await getAndSetSubgoals();
    } catch (e) { }
  };

  const addSubGoal = async () => {
    try {
      const { id } = await handleAddElement({
        type: CONTENT_ENUM.SUB_GOAL,
        name: NEW_SUB_GOAL,
        text: NEW_QUESTION,
        parentId: Number(goalId),
      });
      await handleAddElement({
        type: CONTENT_ENUM.OPTION,
        name: NEW_OPTION,
        parentId: id,
      });
      await getAndSetSubgoals();
      let scrollContainer = scrollableNodeRef.current.contentWrapperEl;
      scrollContainer.scrollTo({
        top: 40000,
        behavior: "smooth",
      });
    } catch (e) { }
  };

  const addOption = async (id: number) => {
    try {
      await handleAddElement({
        type: CONTENT_ENUM.OPTION,
        name: NEW_OPTION,
        parentId: id,
      });
      await getAndSetSubgoals();
      let scrollContainer = scrollableNodeRef.current.contentWrapperEl;
      let childs = goal?.children.filter((child) => child.id === id)[0].children
        .length;
      scrollContainer.scrollTo({
        top: scrollContainer.scrollTop + (childs ? childs++ : 0) * 30,
        behavior: "smooth",
      });
    } catch (e) { }
  };

  const handleGoBack = (navigatePath: string = "") => {
    if (navigatePath) {
      navigate(navigatePath);
    } else {
      navigate(-1);
    }
  };

  const handleSaveNewReport = () => {
    navigate("/summary");
    handleExitEditMode();
  };

  return (
    <div className={styles.pageLayout}>
      <CommonLayout
        contentId={goal?.id}
        title={goal?.name || ""}
        description={goal?.text}
        backButton={
          isEditingReport ? "Back to Report Summary" : "Back to Goals Selection"
        }
        navigatePath={isEditingReport ? `/reports` : `/phases/${phaseId}`}
      >
        <div className={styles.container}>
          {isAdmin && (
            <StepProgressBar goalId={phaseId} className={styles.subgoalsStep} />
          )}
          <ScrollBox
            variant="options"
            className={styles.scrollBoxContainer}
            scrollableNoderef={scrollableNodeRef}
          >
            <>
              {isAdmin && (
                <IconButton variant="addSubGoalLarge" onClick={addSubGoal} />
              )}
              <div className={styles.subGoalContainer}>
                {!!goal?.children.length &&
                  goal.children.map((subGoal) => (
                    <div
                      key={String(subGoal.id) + "container"}
                      className={`${styles.subGoal} ${isAdmin ? styles.subGoalAdmin : ""
                        }`}
                    >
                      <ContentEditor
                        key={String(subGoal.id) + "name"}
                        id={subGoal.id}
                        field={"name"}
                        className={`${styles.subGoalName} ${isAdmin ? styles.admin : ""
                          }`}
                      >
                        <Typography
                          tag="h4"
                          variant="subgoals"
                          className={styles.title}
                        >
                          {subGoal.name}
                        </Typography>
                      </ContentEditor>
                      <ContentEditor
                        key={String(subGoal.id) + "text"}
                        id={subGoal.id}
                        field={"text"}
                        className={`${styles.subGoalName} ${isAdmin ? styles.admin : ""
                          }`}
                      >
                        <Typography
                          tag="h5"
                          variant="subgoals"
                          className={styles.text}
                        >
                          {subGoal.text}
                        </Typography>
                      </ContentEditor>
                      {!!subGoal?.children.length &&
                        subGoal.children.map((option, idx) => (
                          <div
                            className={styles.optionsContainer}
                            key={String(option.id) + "optionContainer"}
                          >
                            <CheckBox
                              onChange={handleSelectedOptionsState(option.id)}
                              className={styles.items}
                              checked={selectedOptionsIds[goalId]?.includes(
                                option.id
                              )}
                              key={String(option.id) + "optionCheck"}
                            >
                              <>
                                <ContentEditor
                                  key={String(option.id) + "option"}
                                  id={option.id}
                                  field={"name"}
                                  className={`${styles.subGoalName} ${isAdmin ? styles.admin : ""
                                    }`}
                                  allowHighlight={true}
                                  isHighlighted={option.highlighted}
                                  onDelete={
                                    subGoal.children.length > 1
                                      ? () =>
                                        setElementToDelete({
                                          name: option.name,
                                          id: option.id,
                                        })
                                      : undefined
                                  }
                                  onAdd={() => addOption(subGoal.id)}
                                  idx={idx}
                                >
                                  <Typography
                                    tag="h5"
                                    variant="subgoals"
                                    className={styles.list}
                                    key={String(option.id) + "optionText"}
                                  >
                                    {option.name}
                                  </Typography>
                                </ContentEditor>
                              </>
                            </CheckBox>
                          </div>
                        ))}
                      {isAdmin && goal.children.length > 1 && (
                        <CloseIcon
                          className={styles.closeIcon}
                          onClick={() =>
                            setElementToDelete({
                              name: subGoal.name,
                              id: subGoal.id,
                            })
                          }
                        />
                      )}
                    </div>
                  ))}
              </div>
            </>
          </ScrollBox>
          {elementToDelete && (
            <Modal
              title={`Remove ${elementToDelete.name}`}
              variant="delete"
              subTitle={`Are you sure you want to delete "${elementToDelete.name}" ?`}
              onClickCancel={() => setElementToDelete(null)}
              onClickYes={() => handleDeleteSubgoal()}
            />
          )}
          {!isAdmin && isEditingReport && (
            <div className={styles.buttonContainer}>
              <Button
                className={
                  !!flatten(Object.values(selectedOptionsIds))?.length
                    ? styles.button
                    : styles.buttonOff
                }
                buttonType="normal"
                variant="primary"
                onClick={handleSaveNewReport}
              >
                Save new report
              </Button>
              <Button
                className={
                  !!flatten(Object.values(selectedOptionsIds))?.length
                    ? styles.button
                    : styles.buttonOff
                }
                buttonType="normal"
                variant="updateReport"
                onClick={() => navigate("/summary")}
              >
                Update report
              </Button>
            </div>
          )}
          {!isAdmin && !isEditingReport && (
            <div className={styles.buttonContainer}>
              <Button
                className={
                  !!flatten(Object.values(selectedOptionsIds))?.length
                    ? styles.button
                    : styles.buttonOff
                }
                buttonType="normal"
                variant="primary"
                onClick={() => navigate("/summary")}
              >
                Create Summary
              </Button>
            </div>
          )}
          {isAdmin && (
            <div
              className={`${styles.buttonContainer} ${styles.buttonContainerAdmin}`}
            >
              <Button
                buttonType="clear"
                variant="navigation"
                onClick={() => handleGoBack()}
              >
                <BackIco />
                goals
              </Button>
              <Button
                buttonType="clear"
                variant="navigation"
                onClick={() => handleGoBack(`/phases/${phaseId}`)}
              >
                back to phases
              </Button>
            </div>
          )}
        </div>
      </CommonLayout>
    </div>
  );
};

export default Options;
