import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "components/atoms/Logo";
import styles from "./LoginForm.module.scss";
import Typography from "components/atoms/Typography";
import SocialButton, { SBVariants } from "components/atoms/SocialButton";
import { useAuth } from "state/AuthState";

const LoginForm = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  // const [loginDetails, setLoginDetails] = useState<{
  //   userName: string;
  //   password: string;
  //   userNameError: string;
  //   passwordError: string;
  // }>({
  //   userName: "",
  //   password: "",
  //   userNameError: "",
  //   passwordError: "",
  // });

  useEffect(() => {
    if (auth.user) {
      navigate("/", { replace: true });
    }
  }, [auth, navigate]);

  // const handleInputChange = (key: string) => (value: string) => {
  //   setLoginDetails((values) => ({
  //     ...values,
  //     [key]: value,
  //     [`${key}Error`]: "",
  //   }));
  // };

  // const handleSubmit = () => {
  //   setLoginDetails({
  //     ...loginDetails,
  //     userNameError: !loginDetails.userName
  //       ? "Please fill in your Email or User name"
  //       : "",
  //     passwordError: !loginDetails.password
  //       ? "Please fill in your password"
  //       : "",
  //   });
  //
  //   if (loginDetails.password && loginDetails.userName) {
  //     auth.signin({ userData: loginDetails, provider: "jwt" }, () => {
  //       navigate("/", { replace: true });
  //     });
  //   }
  // };

  const handleGoogleAuthClick = () => {
    auth.signin({ provider: "google" }, () => {
      navigate("/", { replace: true });
    });
  };

  return (
    <form className={styles.form}>
      <div className={styles.titles}>
        <Logo className={styles.logo} type="aeNoText" />
        <Typography tag="h1" variant="login">
          Log in to Agile® (DA®)
        </Typography>
        <Typography tag="h3" variant="login">
          Welcome back! Please sign in.
        </Typography>
      </div>
      <div className={styles.inputs}>
        {/*<Input*/}
        {/*  type="email"*/}
        {/*  label="Email or User name"*/}
        {/*  placeholder="Enter your Email or User name"*/}
        {/*  onChange={handleInputChange("userName")}*/}
        {/*  error={loginDetails.userNameError}*/}
        {/*/>*/}
        {/*<Input*/}
        {/*  type="password"*/}
        {/*  label="Password"*/}
        {/*  placeholder="Enter your password"*/}
        {/*  onChange={handleInputChange("password")}*/}
        {/*  error={loginDetails.passwordError}*/}
        {/*/>*/}
        {/*<Button variant="default" buttonType="slim" onClick={handleSubmit}>*/}
        {/*  Sign in*/}
        {/*</Button>*/}
        {/*<span className={styles.span}>or</span>*/}
        <div className={styles.socialButtons}>
          {/*<SocialButton disabled variant={SBVariants.MICROSOFT} />*/}
          {/*<SocialButton disabled variant={SBVariants.SAML} />*/}
          {/*<SocialButton disabled variant={SBVariants.AZURE} />*/}
          <SocialButton
            onClick={handleGoogleAuthClick}
            variant={SBVariants.GOOGLE}
            wide
          >
            Sign in with Google
          </SocialButton>
        </div>
      </div>
    </form>
  );
};

export default LoginForm;
