import React from "react";
import styles from "./SocialButton.module.scss";

export enum SBVariants {
  MICROSOFT = "microsoft",
  AZURE = "azure",
  SAML = "saml",
  GOOGLE = "google",
}

interface SocialButtonProps {
  variant: SBVariants;
  disabled?: boolean;
  onClick?: VoidFunction;
  wide?: boolean;
  children?: string;
}

const SocialButton = ({
  variant,
  disabled = false,
  onClick = () => {},
  wide = false,
  children = "",
}: SocialButtonProps) => (
  <button
    className={`${styles.socialButton} ${styles["variant-" + variant]} ${
      wide ? styles.wide : ""
    }`}
    onClick={onClick}
    disabled={disabled}
    type="button"
  >
    {children}
  </button>
);

export default SocialButton;
