import React, {
  useState,
  useMemo,
  useCallback,
  ReactNode,
  useContext,
} from "react";
import { OPTIONS_CHECKED } from "utils/constants";

type SelectedOptionsIds = { [key: string]: number[] };

const SelectedOptionsContext = React.createContext<{
  selectedOptionsIds: SelectedOptionsIds;
  setSelectedOptionsIds: (selectedOptions: SelectedOptionsIds) => void;
}>(null!);

export const SelectedOptionsProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const optionsPersisted = localStorage.getItem(OPTIONS_CHECKED);
  const [selectedOptionsIds, setSelectedOptionsIds] =
    useState<SelectedOptionsIds>(
      optionsPersisted ? JSON.parse(optionsPersisted) : {}
    );

  const handleSaveSelectedOptionsIds = useCallback(
    (selectedOptions: SelectedOptionsIds) => {
      setSelectedOptionsIds(selectedOptions);
      localStorage.setItem(OPTIONS_CHECKED, JSON.stringify(selectedOptions));
    },
    []
  );

  const stateProviderValue = useMemo(
    () => ({
      selectedOptionsIds,
      setSelectedOptionsIds: handleSaveSelectedOptionsIds,
    }),
    [selectedOptionsIds, handleSaveSelectedOptionsIds]
  );
  return (
    <SelectedOptionsContext.Provider value={stateProviderValue}>
      {children}
    </SelectedOptionsContext.Provider>
  );
};

export const useSelectedOptions = () => {
  return useContext(SelectedOptionsContext);
};
