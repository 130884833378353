import {
  G_AUTH_CODE,
  OPTIONS_CHECKED,
  REFRESH_TOKEN,
  TOKEN,
  USER,
} from "./constants";

const popupCenter = ({
  url,
  title,
  w = 400,
  h = 500,
}: {
  url: string;
  title: string;
  w?: number;
  h?: number;
}) => {
  const dualScreenLeft =
    window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop =
    window.screenTop !== undefined ? window.screenTop : window.screenY;

  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
    ? document.documentElement.clientWidth
    : // eslint-disable-next-line no-restricted-globals
      screen.width;
  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : // eslint-disable-next-line no-restricted-globals
      screen.height;

  const systemZoom = width / window.screen.availWidth;
  const left = (width - w) / 2 / systemZoom + dualScreenLeft;
  const top = (height - h) / 2 / systemZoom + dualScreenTop;
  const newWindow = window.open(
    url,
    title,
    `
      scrollbars=yes,
      width=${w / systemZoom}, 
      height=${h / systemZoom}, 
      top=${top}, 
      left=${left}
      `
  );

  // @ts-ignore
  if (window.focus) newWindow.focus();

  return newWindow;
};

const clearPersistedData = () => {
  window.localStorage.removeItem(G_AUTH_CODE);
  window.localStorage.removeItem(TOKEN);
  window.localStorage.removeItem(USER);
  window.localStorage.removeItem(REFRESH_TOKEN);
  window.localStorage.removeItem(OPTIONS_CHECKED);
};

export { popupCenter, clearPersistedData };
