import React, { ReactChild } from "react";
import styles from "./Checkbox.module.scss";

interface CheckboxProps {
  className?: string;
  children: string | ReactChild;
  onChange?: (value: boolean) => void;
  checked?: boolean | undefined;
}

const Checkbox = ({
  children,
  className = "",
  onChange = () => {},
  checked,
}: CheckboxProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    onChange(checked);
  };

  return (
    <div className={`${styles.checkbox} ${className}`}>
      <label>
        <input
          className={styles.input}
          type="checkbox"
          defaultChecked={checked}
          onChange={handleChange}
        />
        <span
          className={`${styles.box} ${styles[checked ? "active" : ""]}`}
          aria-hidden="true"
        />
      </label>
      {children}
    </div>
  );
};

export default Checkbox;
