import { useEffect, useState, useMemo, ReactElement, useCallback } from "react";
import gsap from "gsap";
import { useLocation, useMatch } from "react-router-dom";
import AnimationBox from "./AnimationBox";
import styles from "./Animation.module.scss";

const baseAnimFrameWidth = 1440;

interface AnimationLayoutInterface {
  children: ReactElement;
}

const AnimationLayout = ({ children }: AnimationLayoutInterface) => {
  const location = useLocation();
  const [animFrameScale, setAnimFrameScale] = useState<number>(1);
  const [orangeBox, setOrangeBox] = useState({});
  const [purpleBox, setPurpleBox] = useState({});
  const [cyanBox, setCyanBox] = useState({});

  const root = useMatch("/");
  const phases = useMatch("/phases");
  const phaseId = useMatch("/phases/:phaseId");
  const goalId = useMatch("/phases/:phaseId/:goalId");
  const summary = useMatch("/summary");
  const users = useMatch("/users");
  const reports = useMatch("/reports");
  const reportId = useMatch("/reports/:reportId");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const timeline = useMemo(() => gsap.timeline(), [location]);

  const handleAnimFrameScale = useCallback(() => {
    setAnimFrameScale(window.innerWidth / baseAnimFrameWidth);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleAnimFrameScale);
    return () => window.removeEventListener("resize", handleAnimFrameScale);
  }, [handleAnimFrameScale]);

  useEffect(() => {
    if (root) {
      setOrangeBox({
        backgroundColor: "#EC9301",
        x: 644,
        y: 554,
        width: 949,
        height: 993,
        rotate: -45,
        borderRadius: 150,
        opacity: 1,
        duration: 1,
        zIndex: 2,
      });
      setCyanBox({
        backgroundColor: "#54bddc",
        x: -2495,
        y: -417,
        width: 2088,
        height: 2186,
        rotate: -45,
        borderRadius: 150,
        opacity: 1,
        duration: 1,
        zIndex: 3,
      });
      setPurpleBox({
        backgroundColor: "#4724A1",
        x: -1297,
        y: 2186,
        width: 2088,
        height: 2186,
        rotate: -41,
        borderRadius: 150,
        opacity: 1,
        duration: 1,
        zIndex: 1,
      });
    } else if (phases) {
      setOrangeBox({
        backgroundColor: "#EC9301",
        x: 211,
        y: 1465,
        width: 851,
        height: 892,
        rotate: -52,
        borderRadius: 150,
        opacity: 1,
        duration: 1,
        zIndex: 2,
      });
      setCyanBox({
        backgroundColor: "#54bddc",
        x: 115,
        y: -472,
        width: 1200,
        height: 1300,
        rotate: -52,
        borderRadius: 150,
        opacity: 1,
        duration: 1,
        zIndex: 3,
      });
      setPurpleBox({
        backgroundColor: "#4724A1",
        x: -155,
        y: 1515,
        width: 2512,
        height: 2631,
        rotate: -52,
        borderRadius: 150,
        opacity: 1,
        duration: 1,
        zIndex: 1,
      });
    } else if (phaseId) {
      setOrangeBox({
        backgroundColor: "#EC9301",
        x: 678,
        y: 243,
        width: 851,
        height: 892,
        rotate: -60,
        borderRadius: 150,
        opacity: 1,
        duration: 1,
        zIndex: 1,
      });
      setCyanBox({
        backgroundColor: "#54bddc",
        x: 638,
        y: 1806,
        width: 1246,
        height: 1304,
        rotate: -59,
        borderRadius: 150,
        opacity: 1,
        duration: 1,
        zIndex: 3,
      });
      setPurpleBox({
        backgroundColor: "#4724A1",
        x: 135,
        y: 1435,
        width: 2512,
        height: 2631,
        rotate: -60,
        borderRadius: 150,
        opacity: 1,
        duration: 1,
        zIndex: 2,
      });
    } else if (goalId) {
      setOrangeBox({
        backgroundColor: "#EC9301",
        x: 1332,
        y: 148,
        width: 24,
        height: 24,
        rotate: 0,
        borderRadius: 8,
        opacity: 0,
        duration: 1,
        zIndex: 3,
      });
      setCyanBox({
        backgroundColor: "#54bddc",
        x: 1328,
        y: 144,
        width: 32,
        height: 600,
        rotate: 0,
        borderRadius: 10,
        opacity: 0,
        duration: 1,
        zIndex: 2,
      });
      setPurpleBox({
        backgroundColor: "#4724A1",
        x: 736,
        y: 144,
        width: 624,
        height: 600,
        rotate: 0,
        borderRadius: 10,
        opacity: 0,
        duration: 1,
        zIndex: 1,
      });
    } else if (reports) {
      setOrangeBox({
        backgroundColor: "#EC9301",
        x: 211,
        y: 1165,
        width: 801,
        height: 892,
        rotate: -52,
        borderRadius: 150,
        opacity: 1,
        duration: 1,
        zIndex: 2,
      });
      setCyanBox({
        backgroundColor: "#54bddc",
        x: 115,
        y: -572,
        width: 1200,
        height: 1300,
        rotate: -52,
        borderRadius: 150,
        opacity: 1,
        duration: 1,
        zIndex: 3,
      });
      setPurpleBox({
        backgroundColor: "#4724A1",
        x: -155,
        y: 1515,
        width: 2512,
        height: 2631,
        rotate: -52,
        borderRadius: 150,
        opacity: 1,
        duration: 1,
        zIndex: 1,
      });
    } else if (summary) {
      setOrangeBox({
        backgroundColor: "#4724A1",
        x: 1822,
        y: 332,
        width: 1217,
        height: 1217,
        rotate: 116,
        borderRadius: 80,
        opacity: 1,
        duration: 1,
        zIndex: 2,
      });
      setCyanBox({
        backgroundColor: "#54bddc",
        x: 968,
        y: 792,
        width: 200,
        height: 80,
        rotate: 180,
        borderRadius: 10,
        opacity: 0,
        duration: 1,
        zIndex: 3,
      });
      setPurpleBox({
        backgroundColor: "#4724A1",
        x: 736,
        y: 144,
        width: 624,
        height: 800,
        rotate: 0,
        borderRadius: 10,
        opacity: 0,
        duration: 1,
        zIndex: 1,
      });
    } else if (reportId) {
      setOrangeBox({
        backgroundColor: "#EC9301",
        x: 90,
        y: 1129,
        width: 467,
        height: 489,
        rotate: -35,
        borderRadius: 150,
        opacity: 1,
        duration: 1,
        zIndex: 1,
      });
      setCyanBox({
        backgroundColor: "#54bddc",
        x: 1062,
        y: -78,
        width: 1042,
        height: 1091,
        rotate: -33,
        borderRadius: 150,
        opacity: 1,
        duration: 1,
        zIndex: 1,
      });
      setPurpleBox({
        backgroundColor: "#4724A1",
        x: -1524,
        y: 1024,
        width: 624,
        height: 800,
        rotate: 0,
        borderRadius: 10,
        opacity: 0,
        duration: 1,
        zIndex: 1,
      });
    } else if (users) {
      setOrangeBox({
        backgroundColor: "#EC9301",
        x: -250,
        y: 1500,
        width: 851,
        height: 892,
        rotate: -52,
        borderRadius: 150,
        opacity: 1,
        duration: 1,
        zIndex: 2,
      });
      setCyanBox({
        backgroundColor: "#54bddc",
        x: 185,
        y: -645,
        width: 1200,
        height: 1300,
        rotate: -52,
        borderRadius: 150,
        opacity: 1,
        duration: 1,
        zIndex: 3,
      });
      setPurpleBox({
        backgroundColor: "#4724A1",
        x: -155,
        y: -1515,
        width: 2512,
        height: 2631,
        rotate: -52,
        borderRadius: 150,
        opacity: 1,
        duration: 1,
        zIndex: 1,
      });
    } else {
      setOrangeBox({
        backgroundColor: "#EC9301",
        x: 1440,
        y: 0,
        width: 0,
        height: 0,
        rotate: 0,
        borderRadius: 0,
        opacity: 0,
        duration: 1,
      });
      setCyanBox({
        backgroundColor: "#54bddc",
        x: 1440,
        y: 0,
        width: 0,
        height: 0,
        rotate: 0,
        borderRadius: 0,
        opacity: 0,
        duration: 1,
      });
      setPurpleBox({
        backgroundColor: "#4724A1",
        x: 1440,
        y: 0,
        width: 0,
        height: 0,
        rotate: 0,
        borderRadius: 0,
        opacity: 0,
        duration: 1,
      });
    }
  }, [
    goalId,
    location,
    phaseId,
    phases,
    reports,
    reportId,
    root,
    summary,
    users,
  ]);

  return (
    <div className={styles.container}>
      <div
        className={styles.animation}
        style={{ transform: `scale(${animFrameScale})` }}
      >
        <AnimationBox
          className={styles.box}
          to={orangeBox}
          timeline={timeline}
          position={0}
        />
        <AnimationBox
          className={styles.box}
          to={purpleBox}
          timeline={timeline}
          position={0}
        />
        <AnimationBox
          className={styles.box}
          to={cyanBox}
          timeline={timeline}
          position={0}
        />
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default AnimationLayout;
