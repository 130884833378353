export enum CONTENT_ENUM {
  PHASE = "phase",
  GOAL = "goal",
  OPTION = "option",
  SUB_GOAL = "subGoal",
}

export type UserType = {
  id: string;
  email: string;
  role: string;
  name: string;
  profilePictureUrl: string;
};

export type postGoogleAuthResponseType = {
  accessToken: string;
  refreshToken: string;
  user: UserType;
};

type StaticContentType = {
  id: number;
  parentId: null;
  type: string;
  name: string;
  text: string;
};

type PhaseType = {
  id: number;
  parentId: null;
  type: CONTENT_ENUM.PHASE;
  name: string;
  text: string;
};

type GoalType = {
  type: CONTENT_ENUM.GOAL;
  id: number;
  parentId: number;
  name: string;
  text: string;
  children: SubGoalsType[];
};

type OptionsType = {
  type: CONTENT_ENUM.OPTION;
  name: string;
  id: number;
  parentId: number;
  text: null;
  highlighted: boolean;
};

type SubGoalsType = {
  id: number;
  parentId: number;
  type: CONTENT_ENUM.SUB_GOAL;
  name: string;
  text: string;
  children: OptionsType[];
};

export type ReportType = {
  id: number;
  name: string;
  userId: number;
  createdAt: string;
  updatedAt: string;
  contentPoints: PhaseType[];
};

export type ElementType = {
  id: number;
  parentId: null;
  type: string;
  name: string;
  text: string;
};

export type getStaticContentResponseType = StaticContentType;

export type getPhasesResponseType = PhaseType;

export type getGoalsResponseType = PhaseType & {
  children: GoalType[];
};

export type getSubgoalsResponseType = GoalType & {
  children: SubGoalsType[];
};

export type getReportResponseType = PhaseType & {
  children: GoalType[];
};

export type postElementResponseType = ElementType & {
  success: boolean;
};

export type getAllResponseType = PhaseType & {
  children: GoalType[];
};

export type getUsersResponseType = {
  total: number;
  users: UserType[];
};
