import React, { useRef, useEffect } from "react";

interface AnimationBoxInterface {
  className?: string;
  timeline: GSAPTimeline;
  position: number;
  from?: { [key: string]: string | number };
  to?: { [key: string]: string | number };
}
const AnimationBox = ({
  className = "",
  timeline,
  position,
  from = {},
  to = {},
}: AnimationBoxInterface) => {
  const el = useRef<HTMLDivElement>(null!);

  useEffect(() => {
    timeline.set(el.current, from, position).to(el.current, to, position);
  }, [from, to, position, timeline]);

  return <div className={className} ref={el} />;
};

export default AnimationBox;
