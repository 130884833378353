import React, { ReactChild } from "react";
import styles from "./IconButton.module.scss";
import { ReactComponent as EditIco } from "assets/icons/ico-edit.svg";
import { ReactComponent as DownloadIco } from "assets/icons/ico-download.svg";
import { ReactComponent as DeleteIco } from "assets/icons/ico-delete.svg";
import { ReactComponent as ConfirmIco } from "assets/icons/ico-confirm-edit.svg";
import { ReactComponent as RejectIco } from "assets/icons/ico-reject-edit.svg";
import { ReactComponent as PlusIco } from "assets/icons/ico-plus.svg";
import { ReactComponent as CirclePlusIco } from "assets/icons/ico-plus-circle.svg";
import { ReactComponent as StepProgressIco } from "assets/icons/ico-stepbar.svg";
import { ReactComponent as HomeIco } from "assets/icons/home-bar-icon.svg";
import { ReactComponent as TreeIco } from "assets/icons/tree-bar-icon.svg";
import { ReactComponent as UsersIco } from "assets/icons/users-bar-icon.svg";
import { ReactComponent as HighlightIco } from "assets/icons/highlight-icon.svg";
import Typography from "components/atoms/Typography";

interface ButtonProps {
  variant:
  | "edit"
  | "download"
  | "delete"
  | "clear"
  | "confirmEdit"
  | "rejectEdit"
  | "addSubGoal"
  | "addSubGoalLarge"
  | "stepProgressBar"
  | "homeNav"
  | "phasesTitleNav"
  | "usersNav"
  | "highlight";
  className?: string;
  active?: boolean,
  onClick?: () => void;
  disabled?: boolean;
  content?: string | React.Component | React.ReactElement;
}

const IconButton = ({
  className = "",
  active = false,
  onClick = () => { },
  variant,
  content = "",
  disabled = false,
}: ButtonProps) => {
  let IconButtonType: ReactChild | null;
  switch (variant) {
    case "edit":
      IconButtonType = <EditIco className={styles.buttonIcon} />;
      break;
    case "download":
      IconButtonType = <DownloadIco className={styles.buttonIcon} />;
      break;
    case "delete":
      IconButtonType = <DeleteIco className={styles.buttonIcon} />;
      break;
    case "confirmEdit":
      IconButtonType = <ConfirmIco className={styles.buttonIcon} />;
      break;
    case "rejectEdit":
      IconButtonType = <RejectIco className={styles.buttonIcon} />;
      break;
    case "addSubGoal":
      IconButtonType = <PlusIco className={styles.buttonIcon} />;
      break;
    case "highlight":
      IconButtonType = <HighlightIco className={styles.buttonIcon} />;
      break;
    case "addSubGoalLarge":
      IconButtonType = (
        <>
          <Typography tag={"h4"} variant={"subgoals"}>
            Add new sub-goal
          </Typography>
          <CirclePlusIco className={styles.buttonIcon} />
          <Typography tag={"h4"} variant={"subgoals"}>
            Add new sub-goal
          </Typography>
        </>
      );
      break;
    case "stepProgressBar":
      IconButtonType = (
        <>
          {content}
          <StepProgressIco className={styles.buttonIcon} />
        </>
      );
      break;
    case "homeNav":
      IconButtonType = <HomeIco className={styles.buttonIcon} />;
      break;
    case "phasesTitleNav":
      IconButtonType = <TreeIco className={styles.buttonIcon} />;
      break;
    case "usersNav":
      IconButtonType = <UsersIco className={styles.buttonIcon} />;
      break;
    default:
      IconButtonType = null;
  }

  return (
    <button
      type="button"
      className={`${styles.button} ${styles["variant-" + variant]} ${styles[disabled ? "disabled" : ""]
        } ${className} ${active ? styles.activeBtn : ""}`}
      onClick={onClick}
      disabled={disabled}
    >
      {IconButtonType}
    </button>
  );
};

export default IconButton;
