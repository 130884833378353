import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useStaticContentById, useDeleteElement, useAddElement } from "hooks";
import { useAuth } from "state/AuthState";
import type { getPhasesResponseType } from "api/types";
import { CONTENT_ENUM } from "api/types";
import { getPhases } from "api";
import CommonLayout from "layouts/CommonLayout";
import Typography from "components/atoms/Typography";
import Button from "components/atoms/Button";
import Modal from "components/molecules/Modal";
import Scrollbox from "components/molecules/Scrollbox";
import StepProgressBar from "components/molecules/StepProgressBar";
import { useEditReport } from "state/ReportEditState";
import ContentEditor from "components/molecules/ContentEditor";
import { NEW_PHASE, NEW_PHASE_DESCR } from "utils/constants";
import styles from "./Phases.module.scss";

const CONTENT_ID = 2;

const Phases = () => {
  const { handleAddElement } = useAddElement();
  const { elementToDelete, setElementToDelete, handleDelete } =
    useDeleteElement();
  const [phases, setPhases] = useState<getPhasesResponseType[]>([]);
  const staticContent = useStaticContentById(CONTENT_ID);
  const { user } = useAuth();
  const isAdmin: boolean = user?.role === "admin";
  const { isEditingReport, reportData } = useEditReport();
  const navigate = useNavigate();
  const scrollableNodeRef = useRef() as any;

  const addPhase = async () => {
    try {
      await handleAddElement({
        type: CONTENT_ENUM.PHASE,
        name: NEW_PHASE,
        text: NEW_PHASE_DESCR,
        parentId: null,
      });
      await getAndSetPhases();
      const scrollableDiv = scrollableNodeRef.current.contentWrapperEl;

      scrollableDiv.scrollTo({ top: phases.length * 200, behavior: "smooth" });
    } catch (e) {}
  };

  const deletePhase = async () => {
    try {
      await handleDelete("CONTENT");
      await getAndSetPhases();
    } catch (e) {}
  };

  const getAndSetPhases = async () => {
    const phases = await getPhases();
    setPhases([...phases]);
  };

  useEffect(() => {
    getAndSetPhases();
  }, [isEditingReport, reportData]);

  return (
    <CommonLayout
      contentId={CONTENT_ID}
      title={staticContent.name}
      description={staticContent.text}
      backButton="Back to Home"
      navigatePath="/"
      isContentStatic
    >
      <div className={styles.scrollContainer}>
        {isAdmin && <StepProgressBar className={styles.phaseStep} />}
        <div className={styles.phasesContainer}>
          <Scrollbox
            variant="phases"
            className={styles.phasesScrollbox}
            scrollableNoderef={scrollableNodeRef}
          >
            <div className={styles.phases}>
              {phases.length > 0 && (
                <div className={styles.container}>
                  {phases.map((phase) => (
                    <ContentEditor
                      key={phase.id}
                      field="name"
                      id={phase.id}
                      onDelete={() =>
                        setElementToDelete({
                          name: phase.name,
                          id: phase.id,
                        })
                      }
                    >
                      <Typography
                        tag="span"
                        variant="phases"
                        onClick={() => navigate(`/phases/${phase.id}`)}
                        className={styles.phaseName}
                      >
                        {phase.name}
                      </Typography>
                    </ContentEditor>
                  ))}
                </div>
              )}
            </div>
          </Scrollbox>
        </div>
        {elementToDelete && (
          <Modal
            title={`Remove ${elementToDelete.name} phase`}
            variant="delete"
            subTitle={`Are you sure, delete the ${elementToDelete.name} phase?`}
            onClickCancel={() => setElementToDelete(null)}
            onClickYes={() => deletePhase()}
          />
        )}
        {isAdmin && (
          <div className={styles.buttonContainer}>
            <Button
              className={styles.addPhase}
              buttonType="normal"
              variant="primary"
              onClick={addPhase}
            >
              ADD NEW PHASE
            </Button>
          </div>
        )}
      </div>
    </CommonLayout>
  );
};

export default Phases;
