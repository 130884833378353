import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CommonLayout from "layouts/CommonLayout";
import { useEditReport } from "state/ReportEditState";
import Button from "components/atoms/Button";
import styles from "./Home.module.scss";
import { useStaticContentById } from "hooks";
import { useAuth } from "state/AuthState";
import { useSelectedOptions } from "state/SelectedOptionsState";

const CONTENT_ID = 1;

const Home = () => {
  const navigate = useNavigate();
  const { handleExitEditMode } = useEditReport();
  const staticContent = useStaticContentById(CONTENT_ID);
  const { user } = useAuth();
  const { setSelectedOptionsIds } = useSelectedOptions();
  const isUser: boolean = user?.role === "user";

  const handleCreateProjectStrategy = () => {
    setSelectedOptionsIds({});
    navigate("/phases");
  };

  useEffect(() => {
    handleExitEditMode(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CommonLayout
      contentId={CONTENT_ID}
      title={staticContent.name}
      description={staticContent.text}
      isContentStatic
    >
      <div className={styles.home}>
        <div className={styles.buttons}>
          {isUser && (
            <Button
              variant="secondary"
              buttonType="large"
              className={styles.reports}
              onClick={() => navigate("/reports")}
            >
              MY REPORTS
            </Button>
          )}
          <Button
            buttonType="large"
            onClick={handleCreateProjectStrategy}
            variant="secondary"
          >
            create project strategy
          </Button>
        </div>
      </div>
    </CommonLayout>
  );
};

export default Home;
