import React, { useState } from "react";
import styles from "./ToggleUserRole.module.scss";
import Typography from "components/atoms/Typography";
import Toggle from "components/atoms/Toggle";

interface ToggleUserRoleProps {
  className?: string;
  userId: number;
  userRole: string;
  onChange: (userId: number, userRole: string) => void;
}

const ToggleUserRole = ({
  className = "",
  onChange,
  userId,
  userRole,
}: ToggleUserRoleProps) => {
  const isAdmin = userRole === "Administrator" ? true : false;
  const [isChecked, setIsChecked] = useState(isAdmin);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    onChange(userId, userRole);
    userRole = isChecked ? "Administrator" : "User";

    setIsChecked(checked);
  };
  return (
    <div className={styles.container}>
      <Toggle onChange={handleChange} checked={isChecked} className={className}>
        <Typography
          tag="span"
          variant={isChecked ? "user-role" : "user-roleLight"}
        >
          {userRole}
        </Typography>
      </Toggle>
    </div>
  );
};

export default ToggleUserRole;
