import React, { ReactElement } from "react";
import Logo from "components/atoms/Logo";
import Typography from "components/atoms/Typography";
import Button from "components/atoms/Button";
import { useNavigate } from "react-router-dom";
import ContentEditor from "components/molecules/ContentEditor";
import { useSnackbar } from "state/SnackbarState";
import SnackBar from "components/molecules/Snackbar";
import styles from "./CommonLayout.module.scss";

interface CommonLayoutProps {
  contentId?: number;
  children: ReactElement;
  isContentStatic?: boolean;
  title?: string | { title: string; before?: string; after?: string };
  description?: string;
  variant?: "report" | "tabs";
  backButton?: string;
  navigatePath?: string;
  className?: string;
}

const CommonLayout = ({
  contentId,
  children,
  isContentStatic = false,
  title = "",
  description = "",
  variant = "tabs",
  backButton = "",
  navigatePath = "",
  className,
}: CommonLayoutProps) => {
  const navigate = useNavigate();
  const { snackbarList = [] } = useSnackbar();

  const handleGoBack = () => {
    if (navigatePath) {
      navigate(navigatePath);
    } else {
      navigate(-1);
    }
  };

  const editableTitle = typeof title === "string" ? title : title?.title || "";
  const before = typeof title !== "string" ? title?.before || "" : "";
  const after = typeof title !== "string" ? title?.after || "" : "";

  return (
    <div className={styles.commonLayout}>
      <nav className={`${styles.nav} ${className}`}>
        <div className={styles.logos}>
          <Logo type="aeText" url="https://agileengine.com/" />
        </div>
        <ContentEditor
          className={styles.title}
          field="name"
          isContentStatic={isContentStatic}
          id={contentId}
        >
          <Typography tag="h1" variant="tabs" before={before} after={after}>
            {editableTitle}
          </Typography>
        </ContentEditor>
        <div className={styles.buttonCont}>
          {backButton && (
            <Button
              buttonType="back"
              variant="quaternary"
              onClick={handleGoBack}
            >
              {backButton}
            </Button>
          )}
        </div>
        <div className={styles.container}>
          <ContentEditor
            className={styles.description}
            field="text"
            id={contentId}
            isContentStatic={isContentStatic}
          >
            <Typography tag="p" variant={variant}>
              {description.replace(/(<([^>]+)>)/ig, '')}
            </Typography>
          </ContentEditor>
        </div>
      </nav>
      <div className={`${styles.content} ${styles["" + variant]}`}>
        {children}
      </div>
      {snackbarList.length > 0 && (
        <div className={styles.snackbarContainer}>
          {snackbarList.map((snack) => (
            <SnackBar
              type={snack.type}
              snackBarDescription={snack.response}
              key={snack.id}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default CommonLayout;
