import React, { useState } from "react";
import styles from "./Toggle.module.scss";
interface ToggleProps {
  className?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
  children: React.ReactNode;
}

const Toggle = ({
  className = "",
  onChange = () => {},
  checked = false,
  children,
}: ToggleProps) => {
  const [isChecked, setIsChecked] = useState(checked);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(!isChecked);
    onChange(e);
  };

  return (
    <div className={styles.container}>
      <div className={styles["toggle-switch"]}>
        <label className={styles.label}>
          <input
            type="checkbox"
            className={styles.checkbox}
            checked={isChecked}
            onChange={handleChange}
          />
          <span
            className={`${styles.inner} ${
              isChecked ? styles.innerChecked : ""
            }`}
          />
          <span
            className={`${styles.switch} ${
              isChecked ? styles.switchChecked : ""
            }`}
          />
        </label>
      </div>
      {children}
    </div>
  );
};

export default Toggle;
