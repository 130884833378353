import React, { useEffect, useState } from "react";
import Typography from "components/atoms/Typography";
import errorIcon from "assets/icons/ico-snack-error.svg";
import successIcon from "assets/icons/ico-snack-success.svg";
import closeIcon from "assets/icons/ico-snack-close.svg";
import styles from "./Snackbar.module.scss";

interface SnackbarProps {
  type: "success" | "error";
  snackBarDescription?: string;
  className?: string;
}

const Snackbar = ({
  type,
  snackBarDescription = "",
  className = "",
}: SnackbarProps) => {
  const [isClosed, setIsClosed] = useState<boolean>(false);

  const handleClose = () => {
    setIsClosed(true);
  };

  useEffect(() => {
    const snackBarTimer: ReturnType<typeof setTimeout> = setTimeout(() => {
      setIsClosed(true);
    }, 6000);
    return () => {
      clearTimeout(snackBarTimer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const snackbarImg: {
    [key: string]: JSX.Element;
  } = {
    error: <img className={styles.error} src={errorIcon} alt="error" />,
    success: <img className={styles.success} src={successIcon} alt="success" />,
  };

  return (
    <div
      className={`${
        isClosed ? styles.snackbarOff : styles.container
      } ${className}`}
    >
      <div
        className={`${styles.snackbar} ${styles["type-" + type]} ${className}`}
      >
        {snackbarImg[type]}
        <Typography
          tag="span"
          variant="snackbar"
          className={styles.description}
        >
          {snackBarDescription}
        </Typography>
        <img
          className={styles.close}
          src={closeIcon}
          alt="Close snackbar"
          onClick={() => handleClose()}
        />
      </div>
    </div>
  );
};

export default Snackbar;
