import React, { useState, useEffect } from "react";
import { useNavigate, useMatch } from "react-router-dom";
import { useSnackbar } from "state/SnackbarState";
import NavigationBar from "components/molecules/NavigationBar";
import Typography from "components/atoms/Typography";
import { getMenuContent } from "api";
import type { getAllResponseType } from "api/types";

import styles from "./MenuBar.module.scss";

const MenuBar = () => {
  const [displayElements, setDisplayElements] = useState<boolean>(false);
  const [dataFlow, setDataFlow] = useState<getAllResponseType[]>();
  const { snackbarList = [] } = useSnackbar();
  const navigate = useNavigate();
  const home = useMatch("/");
  const phases = useMatch("/phases");
  const users = useMatch("/users");

  const getAndSetDataFlow = async () => {
    const data = await getMenuContent();
    setDataFlow(data);
  };

  useEffect(() => {
    getAndSetDataFlow();
  }, [snackbarList]);
  return (
    <div
      className={displayElements ? styles.container : styles.containerCollapse}
    >
      <div className={styles.activeMenu}>
        <div
          className={!displayElements ? styles.displayOff : styles.display}
          onClick={() => setDisplayElements(!displayElements)}
        >
          <span className={styles.bar}></span>
          <span className={styles.bar}></span>
          <span className={styles.bar}></span>
        </div>
      </div>
      <hr className={styles.line} />
      <div className={styles.navigation}>
        <NavigationBar
          type="homeNav"
          className={`${!displayElements ? styles.homeOff : styles.home} ${
            home && styles["highlight-homeHighlight"]
          }`}
          onClick={() => navigate("/")}
          content="Home"
        />
        <div className={styles.elementsContainer}>
          <NavigationBar
            type="phasesTitleNav"
            className={`${
              !displayElements ? styles.elementsOff : styles.elements
            } ${phases && styles["highlight-elementsHighlight"]}`}
            content="Phases"
            onClick={() => navigate("/phases")}
          >
            <div
              className={`${
                displayElements ? styles.phases : styles.phasesOff
              }`}
            >
              {!!dataFlow?.length &&
                dataFlow.map((phase) => (
                  <div
                    key={phase.id}
                    className={
                      phase.children.length
                        ? styles.phaseCont
                        : styles.phaseContOff
                    }
                  >
                    <NavigationBar
                      className={styles.phase}
                      type="phasesNav"
                      content={phase.name}
                    >
                      <div className={styles.goals}>
                        {!!phase?.children.length &&
                          phase.children.map((goal) => (
                            <NavigationBar
                              key={goal.id}
                              type="goalsNav"
                              className={
                                goal.children.length
                                  ? styles.goal
                                  : styles.goalOff
                              }
                              content={goal.name}
                            >
                              {!!goal?.children.length &&
                                goal.children.map((subgoal) => (
                                  <Typography
                                    key={subgoal.id}
                                    tag="span"
                                    variant="subgoalsBar"
                                    className={styles.subGoal}
                                  >
                                    {subgoal.name}
                                  </Typography>
                                ))}
                            </NavigationBar>
                          ))}
                      </div>
                    </NavigationBar>
                  </div>
                ))}
            </div>
          </NavigationBar>
        </div>
        <NavigationBar
          type="usersNav"
          className={`${!displayElements ? styles.usersOff : styles.users} ${
            users && styles["highlight-usersHighlight"]
          }`}
          onClick={() => navigate("/users")}
          content="Users"
        />
      </div>
    </div>
  );
};

export default MenuBar;
