import React from "react";
import { useNavigate, Navigate } from "react-router-dom";
import UserMenu from "components/organisms/UserMenu";
import MenuBar from "components/organisms/MenuBar";
import { useAuth } from "state/AuthState";
import { useSelectedOptions } from "state/SelectedOptionsState";
import { USER } from "../utils/constants";

export const AuthStatus = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const { setSelectedOptionsIds } = useSelectedOptions();

  const handleLogOutSideEffects = () => {
    setSelectedOptionsIds({});
    auth.signout(() => navigate("/login"));
  };

  if (!auth.user) {
    return <></>;
  }

  return (
    <>
      {auth.user.role === "admin" && <MenuBar />}
      <UserMenu
        username={auth.user?.name}
        email={auth.user?.email}
        avatarUrl={auth.user?.profilePictureUrl}
        logOut={handleLogOutSideEffects}
      />
    </>
  );
};

export const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const auth = useAuth();
  const userObj = JSON.parse(window.localStorage.getItem(USER) || "null");

  if (!userObj) {
    auth.signout(() => {});
    return <Navigate to="/login" replace />;
  }

  return children;
};
