import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Login from "pages/Login";
import App from "App";
import Home from "pages/Home";
import Phases from "pages/Phases";
import Goals from "pages/Goals";
import Options from "pages/Options";
import Summary from "pages/Summary";
import HistoryReports from "pages/HistoryReports";
import Report from "pages/Report";
import OAuth from "pages/OAuth";
import Users from "pages/Users";
import { RequireAuth } from "layouts/Auth";
import { AuthProvider } from "state/AuthState";
import { SnackbarProvider } from "state/SnackbarState";
import { ReportEditProvider } from "state/ReportEditState";
import AnimationLayout from "layouts/Animation";

function AppRoutes() {
  return (
    <SnackbarProvider>
      <AuthProvider>
        <ReportEditProvider>
          <BrowserRouter>
            <AnimationLayout>
              <Routes>
                <Route path="/" element={<App />}>
                  <Route
                    index
                    element={
                      <RequireAuth>
                        <Home />
                      </RequireAuth>
                    }
                  />
                  <Route path="login" element={<Login />} />
                  <Route path="phases">
                    <Route
                      index
                      element={
                        <RequireAuth>
                          <Phases />
                        </RequireAuth>
                      }
                    />
                    <Route path=":phaseId">
                      <Route
                        index
                        element={
                          <RequireAuth>
                            <Goals />
                          </RequireAuth>
                        }
                      />
                      <Route
                        path=":goalId"
                        element={
                          <RequireAuth>
                            <Options />
                          </RequireAuth>
                        }
                      />
                    </Route>
                  </Route>
                  <Route
                    path="summary"
                    element={
                      <RequireAuth>
                        <Summary />
                      </RequireAuth>
                    }
                  />
                  <Route path="reports">
                    <Route
                      index
                      element={
                        <RequireAuth>
                          <HistoryReports />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path=":reportId"
                      element={
                        <RequireAuth>
                          <Report />
                        </RequireAuth>
                      }
                    />
                  </Route>
                  <Route
                    path="users"
                    element={
                      <RequireAuth>
                        <Users />
                      </RequireAuth>
                    }
                  />
                  <Route path="oauth" element={<OAuth />} />
                  <Route path="*" element={<Navigate to="/" replace />} />
                </Route>
              </Routes>
            </AnimationLayout>
          </BrowserRouter>
        </ReportEditProvider>
      </AuthProvider>
    </SnackbarProvider>
  );
}

export default AppRoutes;
