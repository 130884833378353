import React from "react";
import styles from "./Login.module.scss";
import LoginForm from "components/molecules/LoginForm";
import Carousel from "components/organisms/Carousel";
import carouselFirstBackground from "assets/images/carousel-first-background.png";
import carouselSecondBackground from "assets/images/carousel-second-background.png";
import carouselThirdBackground from "assets/images/carousel-third-background.png";
import LoginLayout from "layouts/LoginLayout";

const Login = () => {
  const carouselData = [
    {
      img: carouselFirstBackground,
      heading: "AgileEngine",
      subHeading: `High performing remote dedicated teams.\n The Disciplined Agile® (DA®) goal-driven approach`,
    },
    {
      img: carouselSecondBackground,
      heading: "AgileEngine",
      subHeading: `High performing remote dedicated teams.\n The Disciplined Agile® (DA®) goal-driven approach`,
    },
    {
      img: carouselThirdBackground,
      heading: "AgileEngine",
      subHeading: `High performing remote dedicated teams.\n The Disciplined Agile® (DA®) goal-driven approach`,
    },
  ];

  return (
    <LoginLayout>
      <div className={styles.login}>
        <Carousel carouselProps={{ infiniteLoop: true }} data={carouselData} />
        <div className={styles.loginForm}>
          <LoginForm />
        </div>
      </div>
    </LoginLayout>
  );
};

export default Login;
