import React, { useState, useCallback, useEffect } from "react";
import { flatten } from "lodash";
import { useNavigate } from "react-router-dom";
import { createHistoryReport, updateReport } from "api";
import type { ReportType } from "api/types";
import { useSelectedOptions } from "state/SelectedOptionsState";
import { useEditReport } from "state/ReportEditState";
import Button from "components/atoms/Button";
import Typography from "components/atoms/Typography";
import CommonLayout from "layouts/CommonLayout";
import ConfirmationLogo from "assets/logos/confirmationLogo.svg";
import styles from "./Summary.module.scss";

const Summary = () => {
  const [reportHistory, setReportHistory] = useState<ReportType>();
  const navigate = useNavigate();
  const { selectedOptionsIds } = useSelectedOptions();
  const {
    isEditingReport,
    handleExitEditMode,
    reportData,
    setIsEditingReport,
  } = useEditReport();

  const getAndSetNewReport = async () => {
    const newReport = await createHistoryReport(
      flatten(Object.values(selectedOptionsIds)).toString(),
      "NEW report"
    );
    setReportHistory(newReport);
  };

  const getAndUpdateReport = async () => {
    const newReport = await updateReport(
      reportData.id,
      flatten(Object.values(selectedOptionsIds)).toString(),
      reportData.name
    );
    setReportHistory(newReport);
  };

  const handleConfirmCreateNewReport = useCallback(() => {
    getAndSetNewReport();
    handleExitEditMode(true);
    // eslint-disable-next-line
  }, []);

  const handleConfirmUpdateReport = useCallback(() => {
    getAndUpdateReport();
    handleExitEditMode(true);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (reportHistory) navigate(`/reports/${reportHistory?.id}`);
    // eslint-disable-next-line
  }, [reportHistory]);

  const handleCancel = () => {
    if (!isEditingReport && reportData) {
      setIsEditingReport(true);
    }
    navigate(-1);
  };

  return (
    <CommonLayout title="Summary Report" variant="report">
      <div className={styles.summary}>
        <div className={styles.confirmation}>
          <img
            className={styles.image}
            src={ConfirmationLogo}
            alt="Agile Engine Confirmation Logo"
          />
          <Typography className={styles.title} tag="h1" variant="confirmation">
            {`Are you ready to ${
              isEditingReport ? "update" : "create"
            } a summary report?`}
          </Typography>
          <div className={styles.buttons}>
            <Button
              buttonType="xlarge"
              variant="default"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              buttonType="xlarge"
              variant="primary"
              onClick={
                isEditingReport
                  ? handleConfirmUpdateReport
                  : handleConfirmCreateNewReport
              }
            >
              Yes
            </Button>
          </div>
        </div>
      </div>
    </CommonLayout>
  );
};

export default Summary;
