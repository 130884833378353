import React from "react";
import {
  Carousel as ReactCarousel,
  CarouselProps,
} from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import styles from "./Carousel.module.scss";

interface Carouselinterface {
  carouselProps: Partial<CarouselProps>;
  data: {
    img: string;
    heading: string;
    subHeading: string;
  }[];
}

const Carousel = ({ carouselProps, data }: Carouselinterface) => (
  <div className={styles.carousel}>
    <ReactCarousel
      {...{
        showThumbs: false,
        showStatus: false,
        showArrows: false,
        dynamicHeight: true,
        autoPlay: true,

        ...carouselProps,
      }}
    >
      {data.map((slide) => (
        <div className={styles.slide} key={slide.img}>
          <img src={slide.img} alt="" />
          <p className={styles.heading}>{slide.heading}</p>
          <p className={styles.subheading}>{slide.subHeading}</p>
        </div>
      ))}
    </ReactCarousel>
  </div>
);

export default Carousel;
