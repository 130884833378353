import React, { ReactNode, useContext, useState } from "react";

type count = {
  id: number;
  name: string;
};

const ReportEditContext = React.createContext({} as any);

export const ReportEditProvider = ({ children }: { children: ReactNode }) => {
  const [isEditingReport, setIsEditingReport] = useState(false);
  const [reportData, setReportData] = useState<count | {}>();

  const handleEditReport = (
    options: string,
    reportId: number,
    reportName: string
  ) => {
    setIsEditingReport(true);
    setReportData({ id: reportId, name: reportName });
    fetchReportTree(options, reportId, reportName);
  };

  const handleExitEditMode = (clear: boolean = false) => {
    setIsEditingReport(false);
    if (clear) {
      setReportData({});
    }
  };

  const fetchReportTree = async (
    options: string,
    reportId: number,
    reportName: string
  ) => {
    const counts: count = {
      id: reportId,
      name: reportName,
    };

    setReportData(counts);
  };

  return (
    <ReportEditContext.Provider
      value={{
        isEditingReport,
        handleExitEditMode,
        handleEditReport,
        reportData,
        setIsEditingReport,
      }}
    >
      {children}
    </ReportEditContext.Provider>
  );
};

export const useEditReport = () => {
  const {
    isEditingReport,
    handleExitEditMode,
    handleEditReport,
    reportData,
    setIsEditingReport,
  } = useContext(ReportEditContext);
  return {
    isEditingReport,
    handleExitEditMode,
    handleEditReport,
    reportData,
    setIsEditingReport,
  };
};
