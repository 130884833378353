import React from "react";
import Typography from "components/atoms/Typography";
import IconButton from "components/atoms/IconButton/IconButton";
import { PathMatch, useMatch, useNavigate } from "react-router-dom";
import styles from "./StepProgressBar.module.scss";
import { ReactComponent as ConnectorIcon } from "assets/icons/icon-connector.svg";

interface StepProgressBarProps {
  className?: string;
  goalId?: number | string;
}

const StepProgressBar = ({ className = "", goalId }: StepProgressBarProps) => {
  const navigate = useNavigate();
  const phases: PathMatch | null = useMatch("/phases");
  const goals: PathMatch<"phaseId"> | null = useMatch("/phases/:phaseId");
  const subgoals: PathMatch<"goalId" | "phaseId"> | null = useMatch(
    "/phases/:phaseId/:goalId"
  );

  return (
    <div className={`${styles.stepper} ${className}`}>
      <div className={!!phases ? styles.phaseActive : styles.phaseStep}>
        <IconButton
          variant="stepProgressBar"
          className={styles.stepIcon}
          content={<span className={styles.content}>1</span>}
        />
        <Typography
          tag="h3"
          variant="stepProgressBar"
          className={styles.phaseText}
          onClick={() => navigate("/phases")}
        >
          Phases
        </Typography>
      </div>

      <ConnectorIcon className={styles.connector} />

      <div
        className={
          !!goals
            ? styles.goalsActive
            : goalId
            ? styles.goalsStep
            : styles["stepOff-goalsOff"]
        }
      >
        <IconButton
          variant={"stepProgressBar"}
          content={<span className={styles.content}>2</span>}
          className={styles.stepIcon}
        />
        <Typography
          tag="h3"
          variant="stepProgressBar"
          className={styles.goalsText}
          onClick={() => navigate(`/phases/${goalId}`)}
        >
          Goals
        </Typography>
      </div>

      <ConnectorIcon className={styles.connector} />

      <div
        className={`${
          !!subgoals ? styles.subgoalsActive : styles["stepOff-subgoalsOff"]
        } `}
      >
        <IconButton
          variant="stepProgressBar"
          content={<span className={styles.content}>3</span>}
          className={styles.stepIcon}
        />
        <Typography
          tag="h3"
          variant="stepProgressBar"
          className={styles.subgoalsText}
        >
          Sub goals
        </Typography>
      </div>
    </div>
  );
};

export default StepProgressBar;
