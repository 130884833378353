import { FormEvent, useState } from "react";
import ContentEditable from "react-contenteditable";
import sanitizeHtml from "sanitize-html";

import {
  NEW_PHASE,
  NEW_GOAL,
  NEW_SUB_GOAL,
  NEW_QUESTION,
  NEW_OPTION,
} from "utils/constants";
import styles from "./Typography.module.scss";

type typographyTagsType = {
  [key: string]: JSX.Element;
};

type typographyType = "p" | "h1" | "h2" | "h3" | "h4" | "h5" | "span";

type typographyVariantsType = {
  p: "tabs" | "report";
  h1: "login" | "tabs" | "confirmation" | "report" | "report1" | "reportName";
  h2: "report" | "modalTitle";
  h3: "goals" | "login" | "stepProgressBar" | "exportReport";
  h4: "subgoals";
  h5: "subgoals" | "reportDate";
  span:
    | "menuSubtitle"
    | "menuItem"
    | "menuItem-logout"
    | "modalButton"
    | "snackbar"
    | "menuBar"
    | "phasesBar"
    | "goalsBar"
    | "phases"
    | "historyReports"
    | "subgoalsBar"
    | "user"
    | "user-role"
    | "user-roleLight"
    | "user-email"
    | "pagination"
    | "pagination-count";
};

interface TypographyProps {
  tag: typographyType;
  variant: typographyVariantsType[typographyType];
  children: string;
  onClick?: VoidFunction;
  className?: string;
  isEditable?: boolean;
  onChange?: (text: string) => void;
  before?: string | JSX.Element;
  after?: string | JSX.Element;
}

const Typography = ({
  tag,
  variant,
  children,
  onClick = () => {},
  className = "",
  isEditable = false,
  onChange = () => {},
  before = "",
  after = "",
}: TypographyProps) => {
  const [value, setValue] = useState<string>(children.replace(/(<([^>]+)>)/ig, ''));

  const handleChange = (e: FormEvent<HTMLFormElement>) => {
    // @ts-ignore
    setValue(e.target.value || " ");
    // @ts-ignore
    onChange(e.target.value || " ");
  };

  const handleClick = () => {
    if (
      value === NEW_PHASE ||
      value === NEW_GOAL ||
      value === NEW_SUB_GOAL ||
      value === NEW_QUESTION ||
      value === NEW_OPTION
    ) {
      setValue("");
    }
    onClick();
  };

  const typographyTags: typographyTagsType = {
    p: (
      <ContentEditable
        html={sanitizeHtml(value || children)}
        className={`${styles[`paragraph-${variant}`]} ${className}`}
        tagName="p"
        disabled={!isEditable}
        onChange={handleChange}
        onClick={onClick}
      />
    ),
    h1: (
      <h1 className={`${styles[`title01-${variant}`]} ${className}`}>
        {before && <span className="titleBefore">{before}</span>}
        <ContentEditable
          html={sanitizeHtml(value || children)}
          tagName="span"
          disabled={!isEditable}
          onChange={handleChange}
          onClick={onClick}
          className={styles.titleEditable}
        />
        {after && <span className="titleAfter">{after}</span>}
      </h1>
    ),
    h2: (
      <ContentEditable
        html={sanitizeHtml(value || children)}
        className={`${styles[`title02-${variant}`]} ${className}`}
        tagName="h2"
        disabled={!isEditable}
        onChange={handleChange}
        onClick={onClick}
      />
    ),
    h3: (
      <h3 className={`${styles[`title03-${variant}`]} ${className}`}>
        {before && <span className="titleBefore">{before}</span>}
        <ContentEditable
          html={sanitizeHtml(value)}
          tagName="span"
          disabled={!isEditable}
          onChange={handleChange}
          onClick={handleClick}
          className={styles.titleEditable}
        />
        {after && <span className="titleAfter">{after}</span>}
      </h3>
    ),
    h4: (
      <ContentEditable
        html={sanitizeHtml(value)}
        className={`${styles[`title04-${variant}`]} ${className}`}
        tagName="h4"
        disabled={!isEditable}
        onChange={handleChange}
        onClick={handleClick}
      />
    ),
    h5: (
      <ContentEditable
        html={sanitizeHtml(value)}
        className={`${styles[`title05-${variant}`]} ${className}`}
        tagName="h5"
        disabled={!isEditable}
        onChange={handleChange}
        onClick={handleClick}
      />
    ),
    span: (
      <ContentEditable
        html={sanitizeHtml(value)}
        className={`${styles[`span-${variant}`]}  ${className}`}
        tagName="span"
        disabled={!isEditable}
        onChange={handleChange}
        onClick={handleClick}
      />
    ),
  };

  return typographyTags[tag];
};

export default Typography;
