import React, { useContext, useState, createContext, ReactNode } from "react";
import { googleAuthProvider, revokeTokenProvider } from "api/authProviders";
import type { AuthProvidersResponse, UserType } from "api/authProviders";
import { clearPersistedData } from "utils";
import { REFRESH_TOKEN, TOKEN, USER } from "utils/constants";

type loginDetailsType = {
  userData?: {
    userName: string;
    password: string;
  };
  provider: "google" | "jwt";
};

const AuthContext = createContext<{
  user: UserType | null;
  signin: (data: loginDetailsType, callback: VoidFunction) => void;
  signout: (callback: VoidFunction) => void;
}>(null!);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const userObj = JSON.parse(window.localStorage.getItem(USER) || "null");
  const [user, setUser] = useState<UserType | null>(userObj);

  const signin = (data: loginDetailsType, callback: VoidFunction) => {
    switch (data.provider) {
      case "google": {
        return googleAuthProvider.signin((resp: AuthProvidersResponse) => {
          setUser(resp.user);
          window.localStorage.setItem(USER, JSON.stringify(resp.user));
          window.localStorage.setItem(TOKEN, resp.accessToken);
          window.localStorage.setItem(REFRESH_TOKEN, resp.refreshToken);
          callback();
        });
      }
      case "jwt":
      default: {
        return window?.alert("Use Google Auth instead, please");
      }
    }
  };

  const signout = (callback: VoidFunction) => {
    return revokeTokenProvider.revoke(() => {
      setUser(null);
      clearPersistedData();
      callback();
    });
  };

  const authValue = {
    user,
    signin,
    signout,
  };

  return (
    <AuthContext.Provider value={authValue}>{children}</AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
