import { useState, useEffect, useCallback } from "react";
import { getStaticContentById } from "api";
import type { getStaticContentResponseType } from "api/types";

const useStaticContentById = (staticId: number) => {
  const [staticContent, setStaticContent] =
    useState<getStaticContentResponseType>({
      id: 0,
      parentId: null,
      type: "",
      name: "",
      text: "",
    });

  const getAndSetStaticContent = useCallback(async (staticId) => {
    const [content] = await getStaticContentById(staticId);
    setStaticContent(content);
  }, []);

  useEffect(() => {
    getAndSetStaticContent(staticId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return staticContent;
};

export default useStaticContentById;
