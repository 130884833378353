import React, { useContext, useState, createContext, ReactNode } from "react";
import { v4 as uuidv4 } from "uuid";

type SnackbarType = {
  response: string;
  type: "error" | "success";
  id: string | number;
};

const SnackbarContext = createContext<{
  snackbar: {
    success: (description: string) => void;
    error: (description: string) => void;
    remove: (snackId: number | string) => void;
  };
  snackbarList: SnackbarType[];
}>(null!);

export const SnackbarProvider = ({ children }: { children: ReactNode }) => {
  const [snackbarList, setSnackbarList] = useState<SnackbarType[]>([]);

  const snackbar = {
    // creates success toast via snackbar.success(string)
    success: (description: string) => {
      let snackId = uuidv4();
      setSnackbarList((old) => [
        ...old.filter((snack) => snack.type !== "success"),
        {
          response: description,
          type: "success",
          id: snackId,
        },
      ]);
      setTimeout(() => snackbar.remove(snackId), 6500);
    },
    // creates error toast via snackbar.error(string)
    error: (description: string) => {
      let snackId = uuidv4();
      setSnackbarList((old) => [
        ...old,
        {
          response: description,
          type: "error",
          id: snackId,
        },
      ]);
      setTimeout(() => snackbar.remove(snackId), 6500);
    },
    // should remove the snack from the array via ID
    remove: (snackId: number | string) => {
      setSnackbarList((old) => [
        ...old.filter((snack) => snack.id !== snackId),
      ]);
    },
  };

  return (
    <SnackbarContext.Provider
      value={{
        snackbar,
        snackbarList,
      }}
    >
      {children}
    </SnackbarContext.Provider>
  );
};

export const useSnackbar = () => {
  return useContext(SnackbarContext);
};
