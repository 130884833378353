import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { AuthStatus } from "./layouts/Auth";
import { SelectedOptionsProvider } from "state/SelectedOptionsState";
import "./index.scss";

const App = () => {
  useEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    if ("scrollRestoration" in history) {
      // eslint-disable-next-line no-restricted-globals
      history.scrollRestoration = "manual";
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    }
  }, []);

  return (
    <>
      <SelectedOptionsProvider>
        <AuthStatus />
        <Outlet />
      </SelectedOptionsProvider>
    </>
  );
};

export default App;
