import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDeleteElement } from "hooks";
import { useSelectedOptions } from "state/SelectedOptionsState";
import { getAllContent, getHistoryReports } from "api";
import moment from "moment";
import type { ReportType, getAllResponseType } from "api/types";
import { CONTENT_ENUM } from "api/types";
import CommonLayout from "layouts/CommonLayout";
import ScrollBox from "components/molecules/Scrollbox/ScrollBox";
import Modal from "components/molecules/Modal";
import ContentEditor from "components/molecules/ContentEditor";
import Typography from "components/atoms/Typography";
import styles from "./HistoryReports.module.scss";
import { useSnackbar } from "state/SnackbarState";
import { flatten } from "lodash";

const HistoryReports = () => {
  const [historyReports, setHistoryReports] = useState<ReportType[]>([]);
  const [allContent, setAllContent] = useState<getAllResponseType[]>([]);
  const { elementToDelete, handleDelete, setElementToDelete } =
    useDeleteElement();
  const { snackbarList = [] } = useSnackbar();
  const { setSelectedOptionsIds } = useSelectedOptions();
  const navigate = useNavigate();

  const getAndSetHistoryReports: () => Promise<void> = async () => {
    setSelectedOptionsIds({});
    const historyReports = await getHistoryReports();
    const content = await getAllContent();
    setAllContent(content);
    setHistoryReports(historyReports);
  };

  const setSelectedOptions = (report: ReportType): any => {
    const selectedOptions = report.contentPoints.map(
      (contentPoint) => contentPoint.id
    );

    const getArray = (array: any, initAcc = []) => {
      return array.children.reduce((acc: [], item: any) => {
        if (item.children) {
          return getArray(item, acc);
        }
        return selectedOptions.includes(item.id) ? [...acc, item.id] : acc;
      }, initAcc);
    };

    const flattenContentIds = (data: any) => {
      return data.reduce((acc: any, line: any) => {
        if (line.children) {
          if (line.type === CONTENT_ENUM.GOAL) {
            const optionsArray = getArray(line);
            if (optionsArray.length > 0) {
              return { ...acc, [line.id]: optionsArray };
            }
          }

          const objects = flattenContentIds(line.children);
          if (Object.keys(objects).length > 0) {
            return { ...acc, [line.id]: objects };
          }
        }
        return acc;
      }, {});
    };

    return Object.values(flattenContentIds(allContent)).reduce(
      (acc: any, obj: any) => ({ ...acc, ...obj }),
      {}
    );
  };

  const deleteHistoryReport: () => Promise<void> = async () => {
    try {
      await handleDelete("REPORT");
      await getAndSetHistoryReports();
    } catch (error) {}
  };

  const handleGoToReport = (
    reportId: number,
    selectedReportOptions: { [key: string]: number[] }
  ) => {
    setSelectedOptionsIds(selectedReportOptions);
    navigate(`/reports/${reportId}`);
  };

  useEffect((): void => {
    getAndSetHistoryReports();
    // eslint-disable-next-line
  }, [snackbarList]);

  return (
    <CommonLayout
      title="My reports"
      backButton="Back to Home"
      description="You have access to the reports, that you've created before.
      You can edit it or create a new report."
      navigatePath="/"
    >
      <div className={styles.scrollContainer}>
        <div className={styles.reportsContainer}>
          <ScrollBox variant="report" className={styles.reportScrollbox}>
            <div className={styles.reports}>
              {!!historyReports.length && (
                <div className={styles.container}>
                  {historyReports.map((report) => (
                    <div className={styles.report} key={report.id}>
                      <ContentEditor
                        selectedOptions={flatten(
                          Object.values(setSelectedOptions(report))
                        ).toString()}
                        field="name"
                        id={report.id}
                        className={styles.reportEditor}
                        onDelete={() => {
                          setElementToDelete({
                            name: report.name,
                            id: report.id,
                          });
                        }}
                        download={{
                          reportName: report.name,
                          selectedReportOptions: flatten(
                            Object.values(setSelectedOptions(report))
                          ),
                        }}
                      >
                        <Typography
                          tag="span"
                          variant="historyReports"
                          onClick={() =>
                            handleGoToReport(
                              report.id,
                              setSelectedOptions(report)
                            )
                          }
                        >
                          {report.name}
                        </Typography>
                      </ContentEditor>
                      <Typography tag="h5" variant="reportDate">
                        {moment(report.createdAt).format("DD.MM.YYYY")}
                      </Typography>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </ScrollBox>
        </div>
        {elementToDelete && (
          <Modal
            title={`Delete Report`}
            variant="delete"
            subTitle={`Are you sure, you want to delete this report?`}
            onClickCancel={() => setElementToDelete(null)}
            onClickYes={deleteHistoryReport}
          />
        )}
      </div>
    </CommonLayout>
  );
};

export default HistoryReports;
