import React, { ReactNode } from "react";
import styles from "./Button.module.scss";

interface ButtonProps {
  variant:
    | "default"
    | "primary"
    | "secondary"
    | "tertiary"
    | "quaternary"
    | "quinary"
    | "confirmation"
    | "newPhase"
    | "visibility"
    | "navigation"
    | "updateReport";
  buttonType: "slim" | "normal" | "large" | "xlarge" | "back" | "clear";
  onClick?: () => void;
  className?: string;
  disabled?: boolean;
  children: string | ReactNode;
}

const Button = ({
  className = "",
  children,
  onClick = () => {},
  variant,
  buttonType,
  disabled = false,
}: ButtonProps) => {
  return (
    <button
      type="button"
      className={`${styles.button} ${styles["variant-" + variant]} ${
        styles["buttonType-" + buttonType]
      } ${styles[disabled ? "disabled" : ""]} ${className}`}
      onClick={onClick}
      disabled={disabled}
    >
      <>{children}</>
    </button>
  );
};

export default Button;
