import React, { useEffect } from "react";
import { googleAuthProvider } from "api/authProviders";

const OAuth = () => {
  useEffect(() => {
    googleAuthProvider.checkAuthCode();
  }, []);

  return <></>;
};

export default OAuth;
