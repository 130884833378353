import { useState, useCallback } from "react";
import { deleteSelectedElement, deleteHistoryReport } from "api";
import { useSnackbar } from "state/SnackbarState";

type deleteElementType = {
  name?: string;
  id?: number;
};

const useDeleteElement = () => {
  const [elementToDelete, setElementToDelete] =
    useState<deleteElementType | null>(null);
  const { snackbar } = useSnackbar();

  const handleDelete = useCallback(
    async (type: "REPORT" | "CONTENT") => {
      setElementToDelete(null);
      try {
        if (elementToDelete?.id) {
          if (type === "REPORT") {
            await deleteHistoryReport(elementToDelete.id);
          }
          if (type === "CONTENT") {
            await deleteSelectedElement(elementToDelete.id);
          }
          snackbar.success(`${elementToDelete.name} deleted successfully`);
        }
      } catch (e) {
        snackbar.error(`${elementToDelete?.name} was not deleted`);
        throw new Error();
      }
    },
    [elementToDelete, snackbar]
  );

  return { handleDelete, elementToDelete: elementToDelete, setElementToDelete };
};

export default useDeleteElement;
