import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDeleteElement, useAddElement } from "hooks";
import { useAuth } from "state/AuthState";
import { getGoals } from "api";
import { CONTENT_ENUM } from "api/types";
import {
  NEW_GOAL,
  NEW_GOAL_DESCR,
  NEW_OPTION,
  NEW_QUESTION,
  NEW_SUB_GOAL,
} from "utils/constants";
import type { getGoalsResponseType } from "api/types";
import CommonLayout from "layouts/CommonLayout";
import Typography from "components/atoms/Typography";
import Button from "components/atoms/Button";
import Modal from "components/molecules/Modal";
import Scrollbox from "components/molecules/Scrollbox";
import ContentEditor from "components/molecules/ContentEditor";
import { ReactComponent as BackIco } from "assets/icons/ico-left-arrow.svg";
import styles from "./Goals.module.scss";
import StepProgressBar from "components/molecules/StepProgressBar";
import { useSelectedOptions } from "../../state/SelectedOptionsState";

const Goals = () => {
  const { phaseId = "" } = useParams();
  const [phase, setPhase] = useState<getGoalsResponseType>();
  const { user } = useAuth();
  const isAdmin: boolean = user?.role === "admin";
  const { handleAddElement } = useAddElement();
  const { elementToDelete, setElementToDelete, handleDelete } =
    useDeleteElement();
  const navigate = useNavigate();
  const scrollableNodeRef = useRef() as any;
  const { selectedOptionsIds } = useSelectedOptions();
  const addGoal = async () => {
    try {
      const { id: goalId } = await handleAddElement({
        type: CONTENT_ENUM.GOAL,
        name: NEW_GOAL,
        text: NEW_GOAL_DESCR,
        parentId: phase?.id,
      });
      const { id: subgoalId } = await handleAddElement({
        type: CONTENT_ENUM.SUB_GOAL,
        name: NEW_SUB_GOAL,
        text: NEW_QUESTION,
        parentId: Number(goalId),
      });
      await handleAddElement({
        type: CONTENT_ENUM.OPTION,
        name: NEW_OPTION,
        parentId: subgoalId,
      });
      await getAndSetPhase();
      const scrollableDiv = scrollableNodeRef.current.contentWrapperEl;
      const offSet = phase?.children.length ? phase.children.length : 1;

      scrollableDiv.scrollTo({ top: offSet * 200, behavior: "smooth" });
    } catch (e) {}
  };

  const deleteGoal = async () => {
    try {
      await handleDelete("CONTENT");
      await getAndSetPhase();
    } catch (e) {}
  };

  const getAndSetPhase = async () => {
    const [goals] = await getGoals(phaseId);
    setPhase(goals);
  };

  useEffect(() => {
    getAndSetPhase();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderCounter = (goalId: number) => {
    const selectedOptionsArray = selectedOptionsIds[goalId]?.length;
    return selectedOptionsArray ? (
      <i className={styles.badge}>{selectedOptionsArray}</i>
    ) : undefined;
  };

  return (
    <CommonLayout
      contentId={phase?.id}
      title={{
        before: "Choose ",
        title: phase?.name || "",
        after: " Goals",
      }}
      description={phase?.text}
      backButton="Back to Phases"
      navigatePath="/phases"
    >
      <div className={styles.scrollContainer}>
        {isAdmin && <StepProgressBar className={styles.goalsStep} />}
        <div className={styles.goalsContainer}>
          <Scrollbox
            variant="goals"
            className={styles.goalsScrollbox}
            scrollableNoderef={scrollableNodeRef}
          >
            <div className={`${styles.goals} ${isAdmin ? styles.admin : ""}`}>
              {!!phase?.children?.length && (
                <div className={styles.container}>
                  {phase.children.map((goal) => (
                    <ContentEditor
                      key={goal.id}
                      field="name"
                      id={goal.id}
                      className={styles.goal}
                      onDelete={() =>
                        setElementToDelete({
                          id: goal.id,
                          name: goal.name,
                        })
                      }
                    >
                      <Typography
                        tag="h3"
                        variant="goals"
                        onClick={() =>
                          navigate(`/phases/${phaseId}/${goal.id}`)
                        }
                        before={renderCounter(goal.id)}
                      >
                        {goal.name}
                      </Typography>
                    </ContentEditor>
                  ))}
                </div>
              )}
            </div>
          </Scrollbox>
        </div>
        {elementToDelete && (
          <Modal
            title={`Remove ${elementToDelete.name} goal`}
            variant="delete"
            subTitle={`Are you sure, delete the ${elementToDelete.name} goal?`}
            onClickCancel={() => setElementToDelete(null)}
            onClickYes={deleteGoal}
          />
        )}
        {isAdmin && (
          <div className={styles.buttonContainer}>
            <Button buttonType="normal" variant="primary" onClick={addGoal}>
              ADD NEW GOAL
            </Button>
            <Button
              buttonType="clear"
              variant="navigation"
              className={styles.backButton}
              onClick={() => navigate("/phases")}
            >
              <BackIco />
              phases
            </Button>
          </div>
        )}
      </div>
    </CommonLayout>
  );
};

export default Goals;
