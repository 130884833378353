import React, { useEffect, useRef } from "react";
import styles from "./UserMenu.module.scss";
import Avatar from "components/atoms/Avatar";
import Typography from "components/atoms/Typography";
import Modal from "components/molecules/Modal";

interface UserMenuProps {
  username: string;
  email: string;
  avatarUrl?: string;
  logOut: () => void;
}

const UserMenu = ({
  username,
  email,
  avatarUrl = "",
  logOut,
}: UserMenuProps) => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [isLogoutModalOpen, setIsLogoutModalOpen] = React.useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const handleMenuChange = React.useCallback((): void => {
    setIsMenuOpen(!isMenuOpen);
  }, [isMenuOpen]);

  const handleModalChange = React.useCallback((): void => {
    setIsLogoutModalOpen(!isLogoutModalOpen);
  }, [isLogoutModalOpen]);

  const handleLogout = React.useCallback((): void => {
    handleModalChange();
    handleMenuChange();
    logOut();
  }, [handleModalChange, logOut, handleMenuChange]);

  useEffect(() => {
    const checkIfClickedOutside = ({ target }: MouseEvent) => {
      if (isMenuOpen && ref.current && !ref.current.contains(target as Node)) {
        setIsMenuOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isMenuOpen]);

  return (
    <>
      <div className={styles.container} ref={ref}>
        <div className={styles.avatarContainer}>
          <Avatar
            avatarUrl={avatarUrl}
            email={email}
            onClick={handleMenuChange}
          />
        </div>
        <div
          className={`${styles.menu} ${styles[isMenuOpen ? "open" : "closed"]}`}
        >
          <div className={styles.menuContent}>
            <Typography tag="span" variant="menuSubtitle">
              {username}
            </Typography>
            <Typography tag="span" variant="menuItem">
              {email}
            </Typography>
            <hr className={styles.menuDivider} />
            <Typography
              tag="span"
              variant="menuItem-logout"
              onClick={handleModalChange}
            >
              Logout
            </Typography>
          </div>
        </div>
      </div>
      {isLogoutModalOpen && (
        <Modal
          title="Logging out"
          variant="logout"
          subTitle="Do you want to log out?"
          onClickCancel={handleModalChange}
          onClickYes={handleLogout}
        />
      )}
    </>
  );
};

export default UserMenu;
